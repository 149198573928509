import React, { useState } from "react";
import {
  Form,
  Card,
  Col,
  Row,
  Input,
  Flex,
  Button,
  Upload,
  Select,
} from "antd";
import PageHeader from "../../../components/pageHeader";
import UploadUtils from "../../../utilities/uploadUtils";
import ReactQuill from "react-quill";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  DndContext,
  DragOverlay,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  arrayMove,
  verticalListSortingStrategy, // Using this sorting strategy
} from "@dnd-kit/sortable";

const SortableItem = ({ id, index, field, remove }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    index,
  });

  return (
    <Card
      size="small"
      title={`Location ${index + 1}`}
      ref={setNodeRef}
      style={{
        transform: `translate3d(${transform?.x || 0}px, ${transform?.y || 0}px, 0)`,
        transition,
        ...attributes.style,
        marginBottom: 24
      }}
      {...attributes}
      {...listeners}
      extra={
        <MinusCircleOutlined onClick={() => remove(field.name)} />
      }
    >
      <Row gutter={16} style={{ margin: 0 }}>
        <Col span={12}>
          <Form.Item
            label="Location Name"
            name={[field.name, "name"]}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Location Subtitle"
            name={[field.name, "subtitle"]}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Location Image"
            name={[field.name, "location_image"]}
            {...UploadUtils.formItemProps}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Upload {...UploadUtils.buttonPreviewProps}>
              <UploadUtils.CardContent />
            </Upload>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

const ContactUsForm = ({ mode, formFor, editLayout }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(formFor?.en?.data);
  const [itemsb, setItems] = useState(formFor?.en?.data);
  const [activeId, setActiveId] = useState(null);

  // Ensure items have unique IDs
  const items = data.contact_locations_details.map((item, index) => ({ id: index.toString(), ...item }));

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    })
  );

  const handleSubmit = (values) => {
    try {
      const seo_setting = [
        {
          id: formFor.seo_setting?.[0]?.id,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          feature_image: values.feature_image,
        },
      ];
      values.contact_locations_details = values.contact_locations_details.map(location => ({
        ...location,
        location_image: UploadUtils.processUploadImage(location.location_image)
      }));
      setData(data);
      editLayout(values, "Contact Us", seo_setting);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex(item => item.id === active.id);
      const newIndex = items.findIndex(item => item.id === over.id);

      if (oldIndex !== -1 && newIndex !== -1) {
        const updatedLocations = arrayMove(
          items,
          oldIndex,
          newIndex
        );

        setData(prevData => ({
          ...prevData,
          contact_locations_details: updatedLocations.map(({ id, ...rest }) => rest)
        }));

        form.setFieldsValue({
          contact_locations_details: updatedLocations.map(({ id, ...rest }) => rest)
        });

        setItems(updatedLocations); 
      }
    }

    setActiveId(null);
  };

  return (
    <Form
      form={form}
      disabled={mode === "View"}
      layout="vertical"
      initialValues={data}
      onFinish={(values) => handleSubmit(values)}
    >
      <PageHeader title={`${formFor?.title.replace("-", " ")} Page`} />
      <Row gutter={16} className="mt30">
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Contact Title">
            <Form.Item
              label="Main Title"
              name="main_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ marginBottom: 30 }}>
          <Card
            size="small"
            title="Contact Details"
            style={{ marginBottom: "20px" }}
          >
            <div
              style={{
                display: "flex",
                gap: 20,
                flexDirection: "column",
              }}
            >
              <Card size="small" title="Advice, Sales & Support">
                <Form.Item
                  label="Toll Free Number"
                  name={["advice_sales_support", "sales_toll_free_number"]}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="US Local Number"
                  name={["advice_sales_support", "us_local_number"]}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Card>
              <Card size="small" title="Talk To Live">
                <Form.Item
                  label="Name"
                  name={["talk_to_live", "name"]}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={"Select Link Type"}
                  name={["talk_to_live", "link_type"]}
                >
                  <Select
                    options={[
                      {
                        value: "internal",
                        label: "Internal",
                      },
                      {
                        value: "external",
                        label: "External",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.talk_to_live?.link_type !==
                    currentValues.talk_to_live?.link_type
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    let type = getFieldValue(["talk_to_live", "link_type"]);

                    return (
                      <>
                        {type === "external" && (
                          <Form.Item
                            label={"Link Url"}
                            name={["talk_to_live", "redirect_path"]}
                          >
                            <Input />
                          </Form.Item>
                        )}
                        {type === "internal" && (
                          <>
                            <Form.Item
                              label={"Link Url"}
                              name={["talk_to_live", "redirect_path"]}
                            >
                              <Input addonBefore="https://repair-customer.zen5.io" />
                            </Form.Item>
                          </>
                        )}
                      </>
                    );
                  }}
                </Form.Item>
              </Card>
              <Card size="small" title="Wholesale">
                <Form.Item
                  label="Toll Free Number"
                  name={["wholesale", "toll_free_number"]}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Card>
            </div>
          </Card>
        </Col>

        <Col span={24} style={{ marginBottom: 30 }}>
          {/* <Card size="small" title="Locations">
            <Form.List name="contact_locations_details" initialValues={[]}>
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "column",
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      size="small"
                      title={`Location ${index + 1}`}
                      key={field.key}
                      extra={
                        <>
                          {fields.length > 1 && (
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          )}
                        </>
                      }
                    >
                      <Row gutter={16} style={{ margin: 0 }}>
                        <Col span={12}>
                          <Form.Item
                            label="Location Name"
                            name={[field.name, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Location Subtitle"
                            name={[field.name, "subtitle"]}
                            rules={[
                              {
                                required: true,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Location Image"
                            name={[field.name, "location_image"]}
                            {...UploadUtils.formItemProps}
                            rules={[
                              {
                                required: true,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Upload {...UploadUtils.buttonPreviewProps}>
                              <UploadUtils.CardContent />
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Another Location
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>
          </Card> */}

          <Card size="small" title="Locations">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              onDragStart={(event) => setActiveId(event.active.id)}
            >
              <SortableContext
                items={items.map(item => item.id)}
                strategy={verticalListSortingStrategy} // Using verticalListSortingStrategy
              >
                <Form.List name="contact_locations_details" initialValues={data.contact_locations_details}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <SortableItem
                          key={items[index].id}
                          id={items[index].id}
                          index={index}
                          field={field}
                          remove={remove}
                        />
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Another Location
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </SortableContext>
              {/* <DragOverlay>
                {activeId ? (
                  <Card
                    size="small"
                    title={`Dragging ${items.find(item => item.id === activeId)?.name || 'Item'}`}
                  >
                  </Card>
                ) : null}
              </DragOverlay> */}
            </DndContext>
          </Card>
        </Col>

        <Col span={24}>
          <Flex justify="end" style={{ padding: 24 }} gap={16}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Page
              </Button>
            </Form.Item>
          </Flex>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactUsForm;
