import { theme } from "antd";
import React from "react";
import themeConfig from "../config/theme.json";

export default function PageHeader({ title = "", actions = [] }) {
  const { token } = theme.useToken(themeConfig);
  return (
    <div className='page-header-wrapper'>
      <div className='page-header-title' style={{ color: token.colorText }}>
        {title}
      </div>
      <div className='page-header-actions'>
        {actions.map((element, index) => {
          return (
            <div className='page-header-action' key={index}>
              {element}
            </div>
          );
        })}
      </div>
    </div>
  );
}
