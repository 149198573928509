const endpoints = {
  login: "/user-management/admin-login",
  login_auth0: "/user-management/auth0login",
  me: "/user-management/me",
  logout: "/user-management/logout",
  get_users: "/user-management/admin",
  add_user: "/user-management/admin",
  get_user: "/user-management/admin",
  add_user_comment: "/user-management/admin/comment",
  update_user_comment: "/user-management/admin/comment",
  update_user: "/user-management/user",
  delete_user: "/user-management/deactive",
  reactive_user: "/user-management/backend-reactive-account",
  set_user_filter: "/core/admin/filter",
  password_reset: "/user-management/password-reset",
  forgot_password: "/user-management/admin-password-reset-link",
  get_roles: "/user-management/roles",
  get_permissions: "/user-management/permissions",
  update_profile: "/user-management/user",
  get_customers: "/user-management/user",
  add_customer: "/user-management/user",
  add_customer_comment: "/user-management/user/comment",
  update_customer_comment: "/user-management/user/comment",
  set_customer_filter: "/core/users/filter",
  attachment: "/core/attachment",
  get_product_category: "/product-management/product-category",
  delete_product_category: "/product-management/product-category",
  deactive_product_category: "/product-management/product-category-deactive",
  reactive_product_category: "/product-management/product-category-reactive",
  set_product_category_filter: "/core/product-category/filter",
  add_product_category: "/product-management/product-category",
  update_product_category: "/product-management/product-category",
  show_product_category: "/product-management/product-category",
  get_product_collection: "/product-management/product-collection",
  delete_product_collection: "/product-management/product-collection",
  add_product_collection: "/product-management/product-collection",
  update_product_collection: "/product-management/product-collection",
  show_product_collection: "/product-management/product-collection",
  set_product_collection_filter: "/core/product-collection/filter",
  get_product_type: "/product-management/product-type",
  delete_product_type: "/product-management/product-type",
  add_product_type: "/product-management/product-type",
  update_product_type: "/product-management/product-type",
  show_product_type: "/product-management/product-type",
  set_product_type_filter: "/core/product-type/filter",
  get_product_attribute: "/product-management/product-attribute",
  delete_product_attribute: "/product-management/product-attribute",
  add_product_attribute: "/product-management/product-attribute",
  update_product_attribute: "/product-management/product-attribute",
  show_product_attribute: "/product-management/product-attribute",
  set_product_attribute_filter: "/core/product-attribute/filter",
  get_product_brand: "/product-management/product-brand",
  delete_product_brand: "/product-management/product-brand",
  add_product_brand: "/product-management/product-brand",
  update_product_brand: "/product-management/product-brand",
  show_product_brand: "/product-management/product-brand",
  set_product_brand_filter: "/core/product-brand/filter",
  get_product_tag: "/product-management/product-tag",
  delete_product_tag: "/product-management/product-tag",
  add_product_tag: "/product-management/product-tag",
  update_product_tag: "/product-management/product-tag",
  show_product_tag: "/product-management/product-tag",
  get_products: "/product-management/product",
  delete_product: "/product-management/product",
  add_product: "/product-management/product",
  update_product: "/product-management/product",
  set_product_filter: "/core/product/filter",
  set_product_status: "/product-management/product/status",
  get_vendors: "/purchase-management/vendor",
  delete_vendor: "/purchase-management/vendor",
  add_vendor: "/purchase-management/vendor",
  update_vendor: "/purchase-management/vendor",
  set_vendor_filter: "/core/vendor/filter",
  get_purchase_order: "/inventory-management/purchase-order",
  delete_purchase_order: "/inventory-management/purchase-order",
  add_purchase_order: "/inventory-management/purchase-order",
  update_purchase_order: "/inventory-management/purchase-order",
  add_purchase_order_comment: "/inventory-management/purchase-order/comment",
  update_purchase_order_comment: "/inventory-management/purchase-order/comment",
  update_purchase_order_status:
    "/inventory-management/purchase-orders/update-status",
  update_purchase_order_payment_status:
    "/inventory-management/purchase-order/update-payment-status",
  set_purchase_order_filter: "/core/purchase-order/filter",
  get_purchase_recieve: "/inventory-management/purchase-order-receive",
  add_purchase_recieve: "/inventory-management/purchase-order-receive",
  get_warehouses: "/inventory-management/warehouse",
  delete_warehouse: "/inventory-management/warehouse",
  add_warehouse: "/inventory-management/warehouse",
  update_warehouse: "/inventory-management/warehouse",
  set_warehouse_status: "/inventory-management/warehouse/status",
  set_warehouse_filter: "/core/warehouse/filter",
  get_inventorys: "/inventory-management/list",
  add_inventory: "/inventory-management/store",
  set_inventory_filter: "/core/inventory/filter",
  adjust_inventory: "/inventory-management/adjustment",
  get_adjustment: "/inventory-management/adjustment",
  show_adjustment: "/inventory-management/adjustment",
  add_adjustment: "/inventory-management/adjustment",
  set_adjustment_filter: "/core/adjustment/filter",
  convert_adjustment: "/inventory-management/convert-adjust",
  get_orders: "/order-management/order",
  show_order: "/order-management/order",
  add_order_comment: "/order-management/order/comment",
  update_order_comment: "/order-management/order/comment",
  set_order_filter: "/core/order/filter",
  get_transfers: "/inventory-management/inventory-transfer",
  get_external_transfers: "/inventory-management/inventory-transfer",
  add_external_transfers: "/inventory-management/inventory-transfer",
  update_external_transfers: "/inventory-management/inventory-transfer",
  update_external_transfers_status:
    "/inventory-management/inventory-transfer/update-status",
  get_internal_transfers: "/inventory-management/inventory-internal-transfer",
  delete_transfer: "/inventory-management/inventory-transfer",
  delete_internal_transfer: "/inventory-management/inventory-internal-transfer",
  add_internal_transfer: "/inventory-management/inventory-internal-transfer",
  add_transfer: "/inventory-management/inventory-transfer",
  update_transfer: "/inventory-management/inventory-internal-transfer",
  update_internal_transfer_status:
    "/inventory-management/inventory-internal-transfer/update-status",
  add_transfer_comment: "/inventory-management/inventory-transfer/comment",
  update_transfer_comment: "/inventory-management/inventory-transfer/comment",
  add_internal_transfer_comment:
    "/inventory-management/inventory-internal-transfer/comment",
  update_internal_transfer_comment:
    "/inventory-management/inventory-internal-transfer/comment",
  set_internal_transfer_filter: "/core/internal-transfer/filter",
  set_external_transfer_filter: "/core/external-transfer/filter",
  download_invoice: "/order-management/order/download-invoice",
  download_packing_slip: "/order-management/order/download-slip",
  about_page: "/setting/pages/about",
  privacy_page: "/setting/pages/privacy-policy",
  blog_page: "/setting/blog",
  terms_of_service_page: "/setting/pages/terms-of-service",
  return_exchange_policy_page: "/setting/pages/return-exchange-policy",
  page_list: "/setting/pages",
  faq_page: "/setting/faq-page",
  shipping_faq_page: "/setting/pages/shipping-faq-page",
  get_abandoned_cart: "/order-management/abandoned-cart",
  show_abandoned_cart: "/order-management/abandoned-cart",
  update_abandoned_cart: "/order-management/abandoned-cart",
  send_mail_abandoned_cart: "/order-management/abandoned-cart/recovery-email",
  get_category: "/setting/blog-category",
  get_tags: "/setting/blog-tag",
  get_product_review: "/product-management/product-review",
  show_product_review: "/product-management/product-review",
  approve_product_review: "/product-management/product-approve-review",
  delete_product_review: "/product-management/product-review",
  set_product_review_filter: "/core/product-review/filter",
  add_address: "/user-management/address",
  add_batch: "/order-management/batch",
  update_batch: "/order-management/batch",
  get_batchs: "/order-management/batch",
  get_batchs_warehouse_orders: "/order-management/create-batch",
  get_picking_location_orders: "/order-management/create-picking",
  add_picking_order_product: "/order-management/picking",
  get_packing_tray_order: "/order-management/create-packing",
  mark_order_packed: "/order-management/packing",
  get_shipping_listing: "/order-management/packing",
  save_shipping_details: "/order-management/shipping",
  download_packing_slip: "/order-management/download-packing-slip",
  verify_packing_product: "/order-management/verify-packing",
  get_cms_pages_list: "/cms-management/cms-pages",
  get_cms_page: "/cms-management/cms-pages/id",
  get_cms_Page_lang: "/cms-management/cms-pages/id/lang",
  add_cms_pages: "/cms-management/cms-pages",
  edit_cms_pages: "/cms-management/cms-pages/id",
  delete_cms_pages: "/cms-management/cms-pages/id",
  update_cms_page_status: "cms-management/cms-pages-status/id",
  get_product_compatibility: "/product-management/product-compatibility",
  add_product_compatibility: "/product-management/product-compatibility",
  update_product_compatibility: "/product-management/product-compatibility",
  set_product_compatibility_filter: "/core/product_compatibility/filter",
  get_collection: "/product-management/public-collection",
  get_collection_by_slug: "/product-management/public-collection-slug/slug",
  add_discount: "/discount-management/discount",
  edit_discount: "/discount-management/discount/id",
  get_discounts: "/discount-management/discount",
  get_public_products: '/product-management/public-product',
  place_backed_order: '/order-management/order',
  tax_calculate_backed_order: '/order-management/tax-calculate',
  sync_user_in_auth0: '/user-management/sync-user-auth0',
  make_payment: "payment-management/purchase",
  get_payment_token: "payment-management/token",
  setting_store: "/setting/store",
  get_shipping_carriers: "/shipping-management/get-carriers",
  get_list_services: "/shipping-management/list-services",
  create_shipping_label: "/shipping-management/create-label",
  get_shipping_rates: "/shipping-management/get-rates",
  setting_shipping_details: "/shipping-management/shipping",
  get_order_products_listing: '/product-management/product',
  order_product_listing_filter: "/core/order_product/filter",
  order_public_compatibility: "/product-management/public-compatibility",
  order_public_compatibility: "/product-management/public-compatibility",
  get_sales_team: "/order-management/sales-team",
  calculate_add_order_admin: "/order-management/calculate-cart-amount-backend",
};
export default endpoints;
