import React, { useRef } from "react";
import { Layout, Menu, theme, Flex } from "antd";
import { UserContext } from "../contexts/user";
import { useLocation, useNavigate } from "react-router-dom";
import menus from "../constants/menu";
import { Link } from "react-router-dom";
import routes from "../constants/routes";
// import FullLogo from "../images/new-demo-logo.png";
// import FullLogo from "../images/logo-dark.svg";
import FullLogo from "../images/infotainment-repair-logo-red.png";
import themeConfig from "../config/theme.json";

const { Sider } = Layout;
const getActiveItem = (menus, currentPath) => {
  let activeItem = menus.filter((menuItem) => menuItem.key === currentPath);
  if (activeItem.length > 0) {
    return activeItem[0];
  } else {
    let proccessedItems = menus
      .filter((menuItem) => menuItem.children && menuItem.children?.length > 0)
      .map((menuItem) => getActiveItem(menuItem.children, currentPath))
      .filter((menuItem) => menuItem !== null);
    return proccessedItems?.[0] ?? null;
  }
  return null;
};
const getAvailableMenus = (menus, currentPath, userContext) => {
  return menus.filter((menuItem) => {
    if (menuItem.children?.length > 0) {
      menuItem.children = getAvailableMenus(
        menuItem.children,
        currentPath,
        userContext
      );
      return menuItem.children.length > 0;
    } else {
      if (
        menuItem?.requiredPermissions?.filter(
          (n) => userContext?.user?.permissions.indexOf(n) !== -1
        ).length > 0
      ) {
        // delete menuItem.requiredPermissions;
        return true;
      } else {
        // delete menuItem.requiredPermissions;
        return false;
      }
    }
  });
};

const processMenus = (menus, currentPath, userContext) => {
  const activeItem = getActiveItem(menus, currentPath);
  const availableMenu = getAvailableMenus(menus, currentPath, userContext);
  // console.log({
  //   activeItem: activeItem,
  //   availableMenu: availableMenu,
  // });
  return {
    activeItem: activeItem,
    availableMenu: availableMenu,
  };
};

export default function Sidebar({ closed }) {
  // console.log(menus);
  let currentPath = useLocation();
  // const { token } = theme.useToken();
  const token = theme.getDesignToken(themeConfig);
  const navigate = useNavigate();
  const menuRef = useRef();

  currentPath = currentPath.pathname.replace(/^\//, "");

  return (
    <UserContext.Consumer>
      {(userContext) => {
        let { activeItem, availableMenu } = processMenus(
          [...menus],
          "/" + currentPath,
          userContext
        );
        return (
          <Sider theme="light" width={250} collapsed={closed}>
            <div className="sidebar-custom-wrap">
              <div>
                <Link to={routes.DASHBOARD.path}>
                  <Flex
                    // justify={"start"}
                    justify="center"
                    align={closed ? "center" : "flex-start"}
                    style={{
                      paddingBlock: 10,
                      paddingInline: 20,
                      height: 56,
                    }}
                  >
                    {/* {isSmallScreen ? ( */}

                    {/* <img
                      src={closed ? IconLogo : FullLogo}
                      className='nav-logo-responsive'
                      style={{ width: "100%" }}
                      alt='Logo'
                    /> */}

                    {closed ? (
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "20px",
                          color: "#B12525",
                        }}
                      >
                        IR
                      </div>
                    ) : (
                      <img
                        src={FullLogo}
                        className="nav-logo-responsive"
                        // style={{ width: "50%" }}
                        style={{ width: "62%", maxHeight: "58px" }}
                        alt="Logo"
                      />
                    )}

                    {/* 
                    {closed ? 
                    <div style={{ fontWeight: 600, fontSize: '20px' }}>V<span style={{color:'blue'}}>S</span></div>
                     :
                      <div style={{ fontWeight: 600, fontSize: '20px' }}><span style={{color:'blue'}}>VAPES</span>SHOP</div>
                    } */}

                    {/* ) : null} */}
                  </Flex>
                </Link>
                <Menu
                  mode="inline"
                  selectedKeys={["/" + currentPath]}
                  defaultOpenKeys={activeItem?.openMenuKeys ?? []}
                  items={availableMenu}
                  onSelect={(item) => {
                    return item?.item?.props?.labelOnly === true
                      ? null
                      : navigate(item.key);
                  }}
                />
              </div>
              <div>
                {/* <div className='sidebar-user-card'>
                  <div className='user-details-wrap'>
                    <Avatar
                      style={{
                        backgroundColor: "#cfe2fd",
                        color: "#1f3f7d",
                      }}
                      size='small'
                    >
                      {userContext.user.email.charAt(0)}
                    </Avatar>
                    <div className='user-details'>
                      <div className='user-details-name'>
                        {userContext.user.name}
                      </div>
                      <div className='user-details-email'>
                        {userContext.user.email}
                      </div>
                    </div>
                  </div>
                  <div>
                    <Button size='small' onClick={userContext.logout}>
                      <i className='ri-logout-circle-r-line'></i>
                    </Button>
                  </div>
                </div> */}
              </div>
            </div>
          </Sider>
        );
      }}
    </UserContext.Consumer>
  );
}
