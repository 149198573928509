import { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/user";
import { useNavigate } from "react-router-dom";

function AuthChecker(props) {
  const userContext = useContext(UserContext);
  const [displayStatus, setDisplayStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (userContext.status !== "loggedin") {
      setDisplayStatus(null);
    }
    if (
      props.permissions?.filter(
        (n) => userContext?.user?.permissions.indexOf(n) !== -1
      ).length > 0
    ) {
      setDisplayStatus(props.children);
    } else if (props.redirectTo !== undefined) {
      setDisplayStatus("redirect");
    } else {
      setDisplayStatus(null);
    }
  }, [
    userContext.status,
    userContext.user.permissions,
    props.permissions,
    props.redirectTo,
    props.children,
  ]);

  if (displayStatus === null) {
    return props?.notPermissionCase;
  } else if (displayStatus === "redirect") {
    navigate(props.redirectTo);
  } else {
    return displayStatus;
  }
}

export default AuthChecker;
