import React from "react";
import {
  Row,
  Col,
  Card,
  Flex,
  Button,
  Table,
  Input,
  Select,
  Space,
  DatePicker,
  Popover,
  Form,
  Modal,
  message,
  Menu,
  Dropdown,
  Tooltip,
} from "antd";
import AuthChecker from "./authChecker";
import { UserContext } from "../contexts/user";
import requests from "../utilities/api";
import dayjs from "dayjs";
import PageHeader from "./pageHeader";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { MdOutlineAdd } from "react-icons/md";
import { IoFilterOutline } from "react-icons/io5";
import { IoAddOutline, IoChevronDown } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import downloadCSV from "../utilities/downloadCsv";
import { FaRegEdit } from "react-icons/fa";

const { Option } = Select;
const { RangePicker } = DatePicker;
class PageListing extends React.Component {
  state = {
    listingData: {
      loading: false,
      data: [],
      filter: [],
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showQuickJumper: true,
      showSizeChanger: true,
    },
    search: null,
    sorter: [],
    filters: {
      first_name: null,
      location: null,
      status: null,
      email: null,
    },
    isFilterModalOpen: false,
    isEditModalOpen: false,
    modalMode: null,
    modaleFilterId: null,
    selectedComparator: "",
    selectedAmountComparator: "",
    open: false,
    fields: [
      {
        fieldName: null,
        relation: null,
        value: null,
        valueTwo: null,
        showCalanderInFilter: false,
        showBetweenInFilter: false,
      },
    ],
    appliedFilters: [],
    options: {
      fieldNameOptions: [],
      relationOptions: [],
    },
    sorting: [{}],
    sortField: "",
    sortOrder: "asc",
  };

  generateApiParameters = ({ limit = undefined, page = undefined } = {}) => {
    let dataToSend = {
      limit: limit ?? this.state.pagination.pageSize,
      page: page ?? this.state.pagination.current,
    };
    if (this.state.sorter.length > 0) {
      let order_by_columns = {};
      this.state.sorter.forEach((value) => {
        order_by_columns = {
          ...order_by_columns,
          [value.column]:
            value.order === "ascend" ? `${value.column}` : `-${value.column}`,
        };
      });
      dataToSend = {
        ...dataToSend,
        sort: order_by_columns,
      };
    }
    Object.keys(this.state.filters).forEach((columnName) => {
      Object.keys(this.state.filters).forEach((columnName) => {
        if (
          columnName === "orders_count" &&
          columnName !== this.state.selectedComparator
        ) {
          dataToSend = {
            ...dataToSend,
          };
        } else if (
          columnName === "orders_sum_total" &&
          columnName !== this.state.selectedAmountComparator
        ) {
          dataToSend = {
            ...dataToSend,
          };
        } else if (Array.isArray(this.state.filters[columnName])) {
          dataToSend = {
            ...dataToSend,
            [`filters[${columnName}][$eq]`]:
              this.state.filters[columnName].join(" "),
          };
        }
      });
    });

    const { fields } = this.state;
    if (fields.length > 0) {
      fields.forEach((field, index) => {
        const { fieldName, relation, value, valueTwo } = field;
        if (fieldName === "") {
          return;
        }
        if (fieldName?.includes(".")) {
          const fieldNameParts = fieldName.split(".");
          let filterField = dataToSend;
          for (let i = 0; i < fieldNameParts.length; i++) {
            const part = fieldNameParts[i];

            if (i === fieldNameParts.length - 1) {
              if (part === "dob") {
                if (relation === "$between") {
                  filterField[`${part}][${relation}][0`] = value;
                  filterField[`${part}][${relation}][1`] = valueTwo;
                  // dataToSend[`filters[${fieldName}][${relation}][0]`] = value;
                  // dataToSend[`filters[${fieldName}][${relation}][1]`] = valueTwo;
                } else {
                  filterField[`${part}][${relation}`] =
                    dayjs(value).format("YYYY-MM-DD");
                }
              } else {
                if (relation === "$between" && valueTwo) {
                  filterField[`${part}][${relation}][0`] = value;
                  filterField[`${part}][${relation}][1`] = valueTwo;
                } else {
                  filterField[`${part}][${relation}`] = value;
                }
              }
              // filterField[`${part}][${relation}`] = valueTwo ? `${value},${valueTwo}` : value;
            } else {
              filterField.filters = filterField.filters || {};
              filterField.filters[part] = filterField.filters[part] || {};
              filterField = filterField.filters[part];
            }
          }
        } else {
          if (fieldName === "created_at" || fieldName === "adjustment_date") {
            if (relation === "$between") {
              dataToSend[`filters[${fieldName}][${relation}][0]`] = value;
              dataToSend[`filters[${fieldName}][${relation}][1]`] = valueTwo;
            } else {
              dataToSend[`filters[${fieldName}][${relation}]`] =
                dayjs(value).format("YYYY-MM-DD");
            }
          } else if (fieldName === "roles") {
            dataToSend[`filters[${fieldName}][name][${relation}]`] = value;
          } else {
            if (relation === "$between" && valueTwo) {
              dataToSend[`filters[${fieldName}][${relation}][0]`] = value;
              dataToSend[`filters[${fieldName}][${relation}][1]`] = valueTwo;
            } else {
              dataToSend[`filters[${fieldName}][${relation}]`] = value;
            }
          }
        }
      });
    }

    if (![null, undefined, ""].includes(this.state.search)) {
      dataToSend = {
        ...dataToSend,
        search: this.state.search,
      };
    }

    const { sortField, sortOrder } = this.state;
    if (sortField) {
      if (sortOrder === "desc") {
        dataToSend[`sort[0]`] = `${sortField}:desc`;
      } else {
        dataToSend[`sort[0]`] = `${sortField}:asc`;
      }
    }

    let appliedFilters = [];

    if (this.state.fields.length > 0) {
      fields.forEach((field) => {
        const { fieldName, relation, value, valueTwo } = field;
        if (!fieldName || !relation || !value) {
          return;
        }
        appliedFilters.push({
          fieldName: fieldName,
          relation: relation,
          value:
            fieldName === "profile.dob" ||
            fieldName === "created_at" ||
            fieldName === "adjustment_date"
              ? dayjs(value).format("YYYY-MM-DD")
              : value,
          valueTwo: valueTwo,
        });
      });
    }

    this.setState({ appliedFilters: appliedFilters });

    return dataToSend;
  };

  getListingData = async ({ limit, page } = {}) => {
    const api = requests();
    this.setState({
      listingData: { ...this.state.listingData, loading: true },
    });
    try {
      let dataToSend = this.generateApiParameters({ limit, page });
      const response = await api.get(
        this.props.endpoints.getListing,
        dataToSend
      );
      if (response.status === "Success") {
        this.setState({
          listingData: {
            ...this.state.listingData,
            data: response.data.data,
            filter: response.data.filter,
            loading: false,
          },
          pagination: {
            ...this.state.pagination,
            current: response.data.current_page,
            pageSize: response.data.per_page,
            total: response.data.total,
          },
          options: {
            fieldNameOptions: response.data.availableFieldsforModel,
            relationOptions: response.data.availableOperatorforModel,
          },
        });
      }
    } catch (e) {
      this.setState({
        listingData: { ...this.state.listingData, loading: false, data: [] },
      });
    }
  };

  componentDidMount() {
    if (this.context.user?.permissions.includes(this.props.permissions.view)) {
      this.getListingData();
      // console.log('hasFilter', this.props?.filter)
      if (this.props?.filter?.hasDefaultFilter === true) {
        // console.log('filterData', this.props?.filter?.defaultFilterData)
        this.addDefaultFilter(this.props?.filter?.defaultFilterData);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      if (this.props?.filter?.hasDefaultFilter) {
        this.addDefaultFilter(this.props?.filter?.defaultFilterData);
      }
    }
  }

  addDefaultFilter = async (filter) => {
    this.setState(
      {
        fields: [
          {
            fieldName: filter?.fieldName,
            relation: filter?.relation,
            value: filter?.value,
          },
        ],
      },
      () => this.getListingData({ page: 1 })
    );
  };

  exportListing = async ({ limit, page } = {}) => {
    const api = requests();
    this.setState({
      listingData: { ...this.state.listingData, loading: true },
    });
    try {
      let dataToSend = this.generateApiParameters({ limit, page });
      const response = await api.get(
        this.props.endpoints.getListing + "?export=true",
        dataToSend
      );
      downloadCSV(
        response,
        [
          this.props.pageHeader.title.toLowerCase(),
          dayjs().format("YYYY_MM_DD__HH_mm_ss"),
        ].join("__")
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        listingData: { ...this.state.listingData, loading: false },
      });
    }
  };

  generateColumns = (settings) => {
    return settings
      .filter((setting) => setting.displayDefault)
      .map((setting) => ({
        title: setting.label,
        dataIndex: setting.identifier,
        key: setting.identifier,
        align: setting?.align ? setting?.align : "left",
        render: setting.render,
      }));
  };

  handleSort = (field) => {
    const { sortField, sortOrder } = this.state;
    let newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    if (field === sortField) {
      this.setState({ sortOrder: newSortOrder }, () =>
        this.getListingData({ page: 1 })
      );
    } else {
      this.setState({ sortField: field, sortOrder: "asc" }, () =>
        this.getListingData({ page: 1 })
      );
    }
  };

  handleSortSelectChange = (value) => {
    this.setState({ sortField: value }, () => this.getListingData({ page: 1 }));
  };

  handleApplyFilters = () => {
    this.getListingData({ page: 1 });
  };

  handleFieldChange = (index, value) => {
    const { fields } = this.state;
    const updatedFields = [...fields];
    updatedFields[index].fieldName = value;
    updatedFields[index].relation = null;
    updatedFields[index].value = null;
    updatedFields[index].valueTwo = null;

    if (value === "created_at") {
      updatedFields[index].showCalanderInFilter = true;
    } else {
      updatedFields[index].showCalanderInFilter = false;
    }

    this.setState({
      fields: updatedFields,
    });
  };

  handleRelationChange = (index, value) => {
    const { fields } = this.state;
    const updatedFields = [...fields];
    updatedFields[index].relation = value;
    this.setState({ fields: updatedFields });
    if (value === "$between") {
      updatedFields[index].showBetweenInFilter = true;
    } else {
      updatedFields[index].showBetweenInFilter = false;
    }
  };

  handleValueChange = (index, value) => {
    const { fields } = this.state;
    const updatedFields = [...fields];
    updatedFields[index].value = value;
    this.setState({ fields: updatedFields });
  };

  handleValueTwoChange = (index, value) => {
    const { fields } = this.state;
    const updatedFields = [...fields];
    updatedFields[index].valueTwo = value;
    this.setState({ fields: updatedFields });
  };

  handleAddField = () => {
    const { fields } = this.state;
    this.setState({
      fields: [...fields, { fieldName: null, relation: null, value: null }],
    });
  };

  handleRemoveFilter = (index) => {
    const { fields } = this.state;
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    if (updatedFields.length > 0) {
      this.setState({ fields: [...updatedFields] }, () =>
        this.getListingData({ page: 1 })
      );
    } else {
      this.setState(
        { fields: [{ fieldName: null, relation: null, value: null }] },
        () => this.getListingData({ page: 1 })
      );
    }
  };

  handleClearAllFilters = () => {
    this.setState(
      { fields: [{ fieldName: null, relation: null, value: null }] },
      () => this.getListingData({ page: 1 })
    );
  };

  createViewFormRef = React.createRef();

  showModal = () => {
    this.setState({ isFilterModalOpen: true });
  };

  handleOk = async (values) => {
    const finalPayload = {
      ...values,
      filter: { ...this.state.appliedFilters },
    };

    let api = requests();
    this.setState({
      listingData: { ...this.state.listingData, loading: true },
    });
    try {
      let response = await api.post(
        this.props.endpoints.crudFilter,
        finalPayload,
        true
      );
      this.setState({ isFilterModalOpen: false });
      this.setState(
        { listingData: { ...this.state.listingData, loading: false } },
        () => {
          this.getListingData();
        }
      );

      this.createViewFormRef.current.resetFields();
      message.success(response.message);
    } catch (e) {
      this.setState({
        listingData: { ...this.state.listingData, loading: false },
      });
    }
  };

  handleCancel = () => {
    this.setState({ isFilterModalOpen: false });
  };

  filterFormRef = React.createRef();

  showEditModal = (id, label) => {
    this.setState(
      { modalMode: "edit", isEditModalOpen: true, modaleFilterId: id },
      () => {
        this.filterFormRef?.current?.setFieldsValue({
          label: label,
        });
      }
    );
  };

  showDeleteModal = (id, label) => {
    this.setState(
      { modalMode: "delete", isEditModalOpen: true, modaleFilterId: id },
      () => {
        this.filterFormRef?.current?.setFieldsValue({
          label: label,
        });
      }
    );
  };

  handleEditOk = async (values) => {
    let api = requests();
    this.setState({
      listingData: { ...this.state.listingData, loading: true },
    });
    try {
      if (this.state.modalMode === "edit") {
        const finalPayload = {
          ...values,
          filter: { ...this.state.appliedFilters },
        };
        let response = await api.put(
          this.props.endpoints.crudFilter + "/" + this.state.modaleFilterId,
          finalPayload,
          true
        );
        this.setState({
          modalMode: null,
          isEditModalOpen: false,
          modaleFilterId: null,
        });
        this.setState({
          listingData: { ...this.state.listingData, loading: false },
        });
        this.getListingData();
        message.success(response.message);
      } else if (this.state.modalMode === "delete") {
        let response = await api.del(
          this.props.endpoints.crudFilter + "/" + this.state.modaleFilterId
        );
        this.setState({
          modalMode: null,
          isEditModalOpen: false,
          modaleFilterId: null,
        });
        this.setState({
          listingData: { ...this.state.listingData, loading: false },
        });
        this.getListingData();
        message.success(response.message);
      }
    } catch (e) {
      this.setState({
        listingData: { ...this.state.listingData, loading: false },
      });
    }
  };

  handleEditCancel = () => {
    this.setState({
      modalMode: null,
      isEditModalOpen: false,
      modaleFilterId: null,
    });
  };

  handleCustomFilter = (customFilter) => {
    const updatedFilter = customFilter.map((filter) => ({
      ...filter,
      showCalendarInFilter: false,
      showBetweenInFilter: false,
    }));
    this.setState(
      {
        fields: updatedFilter,
      },
      () => {
        this.getListingData({ page: 1 });
      }
    );
  };

  processData = (data) => {
    return data.map((dataItem) => {
      if (dataItem?.children) {
        if (dataItem.children.length > 0) {
          dataItem.children = this.processData(dataItem.children);
        } else {
          delete dataItem.children;
        }
      }

      if (dataItem?.variations) {
        if (dataItem.variations.length > 0) {
          dataItem.children = this.processData(dataItem.variations);
        } else {
          delete dataItem.variations;
        }
      }

      return dataItem;
    });
  };

  render() {
    const columns = this.generateColumns(this.props.listingSettings);

    const { fields } = this.state;

    const { sortField, sortOrder } = this.state;

    const content = (
      <>
        <div className="filter-section-head mb16">
          {fields.length === 0 ? (
            <Flex justify="center" align="center" className="">
              <div>No Filter Available</div>
            </Flex>
          ) : (
            fields &&
            Array.isArray(fields) &&
            fields.map((field, index) => (
              <div key={index}>
                <Space style={{ marginBottom: 8 }} align="center" size="middle">
                  <Select
                    size="middle"
                    style={{
                      width: 160,
                    }}
                    defaultValue="id"
                    value={field.fieldName}
                    onChange={(value) => this.handleFieldChange(index, value)}
                    placeholder="Filter field"
                    allowClear
                  >
                    {this.props.listingSettings.map((field, fieldIndex) =>
                      field.allowedFilters &&
                      field.allowedFilters.length > 0 ? (
                        <Option key={fieldIndex} value={field.identifier}>
                          {field.label}
                        </Option>
                      ) : null
                    )}
                  </Select>
                  <Select
                    style={{
                      width: 160,
                    }}
                    size="middle"
                    // defaultValue={options?.relationOptions[0]}
                    value={field.relation}
                    onChange={(value) =>
                      this.handleRelationChange(index, value)
                    }
                    placeholder="Filter relation"
                    allowClear
                  >
                    {field.fieldName &&
                      this.props.listingSettings
                        .find(
                          (setting) => setting.identifier === field.fieldName
                        )
                        ?.allowedFilters.map((filter, filterIndex) => (
                          <Option key={filterIndex} value={filter.id}>
                            {filter.name}
                          </Option>
                        ))}
                  </Select>

                  {(() => {
                    const columnSettings = this.props.listingSettings.find(
                      (setting) => setting.identifier === field.fieldName
                    );
                    const filterOption = columnSettings?.allowedFilters.find(
                      (filterOption) => filterOption.id === field.relation
                    );

                    if (!filterOption)
                      return (
                        <Input
                          size="middle"
                          value={field.value}
                          onChange={(e) =>
                            this.handleValueChange(index, e.target.value)
                          }
                          style={{ width: 170 }}
                          placeholder="Filter value"
                        />
                      );

                    switch (filterOption?.valueField) {
                      case "text":
                        return (
                          <>
                            {filterOption?.id === "$between" ? (
                              <>
                                <Input
                                  size="middle"
                                  value={field.value}
                                  onChange={(e) =>
                                    this.handleValueChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  style={{ width: 65 }}
                                />
                                <span style={{ margin: "0 6px" }}>-</span>
                                <Input
                                  size="middle"
                                  value={field.valueTwo}
                                  onChange={(e) =>
                                    this.handleValueTwoChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  style={{ width: 65 }}
                                />
                              </>
                            ) : (
                              <Input
                                size="middle"
                                value={field.value}
                                onChange={(e) =>
                                  this.handleValueChange(index, e.target.value)
                                }
                                style={{ width: 170 }}
                                placeholder="Filter value"
                              />
                            )}
                          </>
                        );
                      case "select":
                        const selectOptions = filterOption.selectOptions || {};
                        return (
                          <Select
                            style={{ width: 170 }}
                            size="middle"
                            defaultValue={selectOptions[0]}
                            onChange={(value) =>
                              this.handleValueChange(index, value)
                            }
                            placeholder="Filter value"
                            allowClear
                          >
                            {Object.entries(selectOptions).map(
                              ([key, value]) => (
                                <Option key={key} value={value}>
                                  {key}
                                </Option>
                              )
                            )}
                          </Select>
                        );
                      case "datepicker":
                        return (
                          <DatePicker
                            size="middle"
                            value={
                              fields[index].value
                                ? dayjs(fields[index].value)
                                : fields[index].value
                            }
                            onChange={(value) =>
                              this.handleValueChange(index, value)
                            }
                            disabledDate={(date) => !date.isBefore(dayjs())}
                            style={{ width: 170 }}
                          ></DatePicker>
                        );
                      case "rangepicker":
                        return (
                          <RangePicker
                            size="middle"
                            onChange={(dates, dateStrings) => {
                              this.handleValueChange(index, dateStrings[0]);
                              this.handleValueTwoChange(index, dateStrings[1]);
                            }}
                            disabledDate={(date) => !date.isBefore(dayjs())}
                            format="YYYY-MM-DD"
                            style={{ width: 170 }}
                            value={[
                              fields[index].value
                                ? dayjs(fields[index].value)
                                : null,
                              fields[index].valueTwo
                                ? dayjs(fields[index].value)
                                : null,
                            ]}
                          ></RangePicker>
                        );
                      default:
                        return (
                          <Input
                            size="middle"
                            value={field.value}
                            onChange={(e) =>
                              this.handleValueChange(index, e.target.value)
                            }
                            style={{ width: 170 }}
                            placeholder="Filter value"
                          />
                        );
                    }
                  })()}
                  {fields.length === 1 ? (
                    <></>
                  ) : (
                    <div
                      onClick={() => this.handleRemoveFilter(index)}
                      type="text"
                      size="small"
                    >
                      <CloseOutlined style={{ fontSize: "12px" }} />
                    </div>
                  )}
                </Space>
              </div>
            ))
          )}
        </div>
        <Flex justify="space-between" gap={8}>
          <Button
            type="text"
            onClick={this.handleAddField}
            disabled={this.state.listingData.loading}
          >
            <Flex align="center" gap={4}>
              <MdOutlineAdd />
              Add a Filter
            </Flex>
          </Button>
          {/* {fields.length > 0 && ( */}
          <Flex gap="small">
            <Button
              onClick={() => {
                this.handleClearAllFilters();
              }}
              disabled={this.state.listingData.loading}
            >
              Clear Filters
            </Button>
            <Button
              type="primary"
              onClick={this.handleApplyFilters}
              disabled={this.state.listingData.loading}
            >
              Apply Filters
            </Button>
          </Flex>
          {/* )} */}
        </Flex>
      </>
    );

    return (
      <>
        {/* {console.log(this.props)} */}
        <PageHeader
          title={
            <div style={{ marginLeft: 10 }}>{this.props.pageHeader.title}</div>
          }
          actions={[
            <Flex justify="end" align="center" gap={16} wrap="wrap">
              <>
                {this.props.listingOptions.showFilter ? (
                  <AuthChecker permissions={[this.props.permissions.view]}>
                    <Popover
                      content={content}
                      title=""
                      trigger="click"
                      open={this.state.openFilterPopover}
                      onOpenChange={this.openFilterPopoverChange}
                    >
                      <Button icon={<IoFilterOutline className="" />}>
                        Filter
                      </Button>
                    </Popover>
                  </AuthChecker>
                ) : null}
                <>
                  {this.props.listingOptions.showSorter ? (
                    <AuthChecker permissions={[this.props.permissions.view]}>
                      <Space.Compact>
                        <Tooltip
                          title={
                            sortOrder === "asc"
                              ? "Ascending Order"
                              : "Descending Order"
                          }
                        >
                          <Button
                            icon={
                              sortOrder === "asc" ? (
                                <SortAscendingOutlined />
                              ) : (
                                <SortDescendingOutlined />
                              )
                            }
                            onClick={() => this.handleSort(sortField)}
                            disabled={this.state.listingData.loading}
                          ></Button>
                        </Tooltip>
                        <Select
                          style={{
                            width: 120,
                          }}
                          // defaultValue={options?.fieldNameOptions.length > 0 ? options.fieldNameOptions[0] : undefined}
                          onChange={(value) =>
                            this.handleSortSelectChange(value)
                          }
                          placeholder="Sort by"
                          allowClear
                          disabled={this.state.listingData.loading}
                        >
                          {this.props.listingSettings.map((field, fieldIndex) =>
                            field.allowedFilters &&
                            field.allowedFilters.length > 0 &&
                            field.sorting !== false ? (
                              <Option key={fieldIndex} value={field.identifier}>
                                {field.label}
                              </Option>
                            ) : null
                          )}
                        </Select>
                      </Space.Compact>
                    </AuthChecker>
                  ) : null}
                </>
              </>
              <AuthChecker
                notPermissionCase={null}
                permissions={[this.props.permissions.view]}
              >
                <div className="search-wrapper">
                  <Input
                    prefix={<i className="ri-search-line"></i>}
                    placeholder="Search"
                    value={this.state.search}
                    onChange={(e) => {
                      this.setState({ search: e.target.value }, () => {
                        if (e.target.value === "") {
                          this.getListingData({ page: 1 });
                        }
                      });
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        this.getListingData({ page: 1 });
                      }
                    }}
                    disabled={this.state.listingData.loading}
                  />
                  <Button
                    onClick={() => this.getListingData({ page: 1 })}
                    loading={this.state.listingData.loading}
                    disabled={this.state.listingData.loading}
                  >
                    Search
                  </Button>
                </div>
              </AuthChecker>
              <>
                {this.props.pageHeader.addButton ? (
                  <AuthChecker
                    notPermissionCase={<></>}
                    permissions={[this.props.permissions.create]}
                  >
                    {this.props.pageHeader.addButtonLabel.toLowerCase() ===
                    "add discount" ? (
                      <Button
                        onClick={this.props.showDiscountModal}
                        type="primary"
                        icon={<PlusOutlined />}
                      >
                        {this.props.pageHeader.addButtonLabel}
                      </Button>
                    ) : (this.props.pageHeader.addButtonLabel.toLowerCase() ===
                    "add sales person" ? 
                    <Button
                    onClick={this.props.openSalesModal}
                    type="primary"
                    icon={<PlusOutlined />}
                  >
                    {this.props.pageHeader.addButtonLabel}
                  </Button>
                    :
                      <Link to={this.props.routes.add}>
                        <Button type="primary" icon={<PlusOutlined />}>
                          {this.props.pageHeader.addButtonLabel}
                        </Button>
                      </Link>
                    )}
                  </AuthChecker>
                ) : null}
              </>
              <>
                {this.props.listingOptions.showExport ? (
                  <AuthChecker permissions={[this.props.permissions.view]}>
                    <Button
                      icon={<i className="ri-download-cloud-2-line"></i>}
                      loading={this.state.listingData.loading}
                      onClick={() => this.exportListing({ page: 1 })}
                      disabled={this.state.listingData.loading}
                    >
                      Export
                    </Button>
                  </AuthChecker>
                ) : null}
              </>
            </Flex>,
          ]}
        />
        <Row
          gutter={[32, 32]}
          style={{ margin: 0, marginTop: 16 }}
          align="middle"
        >
          <Col span={24}>
            <AuthChecker permissions={[this.props.permissions.view]}>
              <Row gutter={[0, 24]} align="middle">
                <Col span={24}>
                  {this.props.listingOptions.showFilter ? (
                    <div className="filter-section">
                      <Card size="small">
                        <Flex justify="space-between" align="center">
                          <Flex justify="start" align="center" gap={8}>
                            <Button
                              type="text"
                              size="small"
                              onClick={() => {
                                this.handleClearAllFilters();
                              }}
                              disabled={this.state.listingData.loading}
                            >
                              All
                            </Button>
                            {this.state.listingData &&
                              this.state.listingData.filter &&
                              this.state.listingData.filter.map((f) => {
                                return (
                                  <Dropdown
                                    overlay={
                                      <Menu>
                                        <Menu.Item
                                          key="1"
                                          icon={<FaRegEdit size={16} />}
                                          onClick={() =>
                                            this.showEditModal(f.id, f.label)
                                          }
                                        >
                                          Edit View
                                        </Menu.Item>
                                        <Menu.Item
                                          key="3"
                                          icon={<RiDeleteBinLine size={16} />}
                                          onClick={() =>
                                            this.showDeleteModal(f.id, f.label)
                                          }
                                          danger
                                        >
                                          Delete View
                                        </Menu.Item>
                                      </Menu>
                                    }
                                  >
                                    <Button
                                      type="text"
                                      size="small"
                                      onClick={() =>
                                        this.handleCustomFilter(f.filter)
                                      }
                                    >
                                      <Flex align="center" gap={4}>
                                        <span>{f.label}</span>{" "}
                                        <IoChevronDown size={14} />
                                      </Flex>
                                    </Button>
                                  </Dropdown>
                                );
                              })}
                          </Flex>
                          {this.state.appliedFilters.length > 0 ? (
                            <Button
                              type="text"
                              size="small"
                              onClick={this.showModal}
                              icon={<IoAddOutline />}
                            >
                              Add View
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Flex>
                        <>
                          {this.state.appliedFilters.length > 0 ? (
                            <div className="filters-row">
                              {/* <span>Applied Filters:</span> */}
                              <div className="appplied-filter-container mt6">
                                <span className="applied-filters-list">
                                  {this.state.appliedFilters.map(
                                    (filter, index) => {
                                      return (
                                        <div
                                          className="applied-filter-wrap"
                                          key={index}
                                        >
                                          <div className="applied-filter">
                                            <span>
                                              {
                                                this.props.listingSettings.find(
                                                  (column) =>
                                                    column.identifier ===
                                                    filter.fieldName
                                                )?.label
                                              }
                                            </span>
                                            <div>
                                              {filter.value}
                                              {filter.valueTwo && (
                                                <span>
                                                  {" "}
                                                  - {filter.valueTwo}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                          <div
                                            className="applied-filter-remove"
                                            onClick={() =>
                                              this.handleRemoveFilter(index)
                                            }
                                          >
                                            <i className="ri-close-line"></i>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </>
                      </Card>
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col span={24}>
                  <Table
                    size="middle"
                    columns={columns}
                    loading={this.state.listingData.loading}
                    expandable={
                      this.props.expandedRowRender
                        ? { expandedRowRender: this.props.expandedRowRender }
                        : null
                    }
                    dataSource={
                      this.props?.endpoints?.processData === true
                        ? this.processData(this.state.listingData.data)
                        : this.state.listingData.data
                    }
                    onChange={(pagination, filters, sorter, extra) => {
                      this.setState(
                        {
                          pagination: {
                            ...this.state.pagination,
                            current: pagination.current,
                            pageSize: pagination.pageSize,
                          },
                          sorter: Array.isArray(sorter)
                            ? sorter
                                .filter((col) => col.order !== undefined)
                                .map((sortItem) => {
                                  return {
                                    column: sortItem.field,
                                    order: sortItem.order,
                                  };
                                })
                            : Object.keys(sorter).length > 0
                            ? sorter.order !== undefined
                              ? [
                                  {
                                    column: sorter.field,
                                    order: sorter.order,
                                  },
                                ]
                              : []
                            : [],
                          filters: filters,
                        },
                        () => this.getListingData()
                      );
                    }}
                    pagination={{
                      size: "middle",
                      ...this.state.pagination,
                      pageSizeOptions: ["10", "20", "50"],
                    }}
                    rowKey="id"
                  />
                </Col>
              </Row>
            </AuthChecker>
          </Col>
        </Row>
        <Modal
          title="Create New View"
          footer={<></>}
          open={this.state.isFilterModalOpen}
          onCancel={this.handleCancel}
        >
          <Form
            layout="vertical"
            onFinish={this.handleOk}
            ref={this.createViewFormRef}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="label"
                  label="Name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} className="mb16">
                {this.state.appliedFilters.length > 0 ? (
                  <div className="filters-row">
                    <span>Applied Filters:</span>
                    <div className="appplied-filter-container mt6">
                      <span className="applied-filters-list">
                        {this.state.appliedFilters.map((filter, index) => {
                          return (
                            <div className="applied-filter-wrap" key={index}>
                              <div className="applied-filter">
                                <span>
                                  {
                                    this.props.listingSettings.find(
                                      (column) =>
                                        column.identifier === filter.fieldName
                                    )?.label
                                  }
                                </span>
                                <div>
                                  {filter.value}
                                  {filter.valueTwo && (
                                    <span> - {filter.valueTwo}</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Col>
              <Col span={24}>
                <Flex justify="end" gap={16}>
                  <Button onClick={this.handleCancel}>Cancel</Button>
                  <Form.Item noStyle>
                    <Button type="primary" htmlType="submit">
                      Create view
                    </Button>
                  </Form.Item>
                </Flex>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          title={this.state.modalMode === "edit" ? "Edit View" : "Delete View?"}
          footer={<></>}
          open={this.state.isEditModalOpen}
          onCancel={this.handleEditCancel}
        >
          <Form
            layout="vertical"
            onFinish={this.handleEditOk}
            ref={this.filterFormRef}
          >
            <Row>
              <Col span={24}>
                {this.state.modalMode === "edit" ? (
                  <Form.Item
                    name="label"
                    label="Name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                ) : (
                  <p>
                    This can’t be undone. View will no longer be available in
                    your admin.
                  </p>
                )}
              </Col>
              {this.state.modalMode === "edit" ? (
                <Col span={24} className="mb16">
                  {this.state.appliedFilters.length > 0 ? (
                    <div className="filters-row">
                      <span>Applied Filters:</span>
                      <div className="appplied-filter-container mt6">
                        <span className="applied-filters-list">
                          {this.state.appliedFilters.map((filter, index) => {
                            return (
                              <div className="applied-filter-wrap" key={index}>
                                <div className="applied-filter">
                                  <span>
                                    {
                                      this.props.listingSettings.find(
                                        (column) =>
                                          column.identifier === filter.fieldName
                                      )?.label
                                    }
                                  </span>
                                  <div>
                                    {filter.value}
                                    {filter.valueTwo && (
                                      <span> - {filter.valueTwo}</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </Col>
              ) : null}
              <Col span={24}>
                <Flex justify="end" gap={16}>
                  <Button onClick={this.handleEditCancel}>Cancel</Button>
                  {this.state.modalMode === "edit" ? (
                    <Form.Item noStyle>
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                    </Form.Item>
                  ) : (
                    <Form.Item noStyle>
                      <Button type="primary" htmlType="submit" danger>
                        Delete View
                      </Button>
                    </Form.Item>
                  )}
                </Flex>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    );
  }
}

PageListing.contextType = UserContext;
export default PageListing;
