import { icons } from "antd/es/image/PreviewGroup";
import { MdOutlineDiscount } from "react-icons/md";
import { FaShippingFast } from "react-icons/fa";
import { MdOutlineInventory2 } from "react-icons/md";

const discount_type = [
  {
    name: "Amount off products",
    description: "Discount specific products or collections of products",
    icons: <MdOutlineDiscount />,
    for: "Product discount",
  },
  // {
  //   name: "Buy X get Y",
  //   description: "Discount  products based on a customer's purchase.",
  //   icons: <MdOutlineDiscount />,
  //   for: "Product discount",
  // },
  {
    name: "Amount off order",
    description: "Discount the total order amount.",
    icons: <MdOutlineInventory2 />,
    for: "Order discount",
  },
  // {
  //   name: "Free shipping",
  //   description: "Offer free shipping on an order",
  //   icons: <FaShippingFast />,
  //   for: "Shipping discount",
  // },
];
export { discount_type };
