import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import AuthChecker from "../../../components/authChecker";
import roleAndPermission from "../../../constants/permissions";
import { AiOutlineCopy } from "react-icons/ai";
import routes from "../../../constants/routes";
// import requests from "../../../utilities/api";
import {
  Tag,
  Spin,
  Skeleton,
  Button,
  Form,
  Row,
  Col,
  Card,
  Segmented,
  Input,
  Select,
  Radio,
  Space,
  Checkbox,
  Alert,
  DatePicker,
  TimePicker,
  Flex,
  message,
  Tooltip,
  Image,
  InputNumber,
  Timeline
} from "antd";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import PageHeader from "../../../components/pageHeader";
import requests from "../../../utilities/api";
import endpoints from "../../../constants/endpoints";
import BrowseBox from "../../../components/BrowseBox";
import moment from "moment";
import relativeTime from "dayjs/plugin/relativeTime";
import { useParams } from "react-router-dom";

dayjs.extend(relativeTime);

const dynamic_endpoints = {
  catalog: endpoints.get_product_collection,
  product: endpoints.get_products,
  category: endpoints.get_product_category,
  customers: endpoints.get_customers,
};

const DiscountEdit = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);

  const [schema, setSchema] = useState();
  const [discountFor, setDiscountFor] = useState();
  const [minAmount, setMinAmount] = useState();
  const [minQty, setMinQty] = useState();
  const [minRequirement, setMinRequirement] = useState(() =>
    schema !== "Buy X get Y" ? 1 : 3
  );
  const [discountUses, setDiscountUses] = useState([]);
  const [endDate, setEndDate] = useState(false);
  const [code, setCode] = useState();
  const [conditionTypeIds, setConditionTypeIds] = useState([]);
  const [customerConditionTypeIds, setCustomerConditionTypeIds] = useState([]);
  const [discountTimeline, setDiscountTimeline] = useState([]);

  const fetchData = async (type, ids, fn) => {
    console.log(type, ids, fn);
    setLoading(true);

    let newType;
    if (type === "collection") {
      newType = "catalog";
    } else {
      newType = type;
    }

    const api = requests();
    try {
      const response = await api.get(dynamic_endpoints[newType]);

      fn(response?.data?.data?.filter((i) => ids.includes(i.id)));
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}/${month}/${day}`;
  };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const navigate = useNavigate();

  const addDiscount = async (value) => {
    const api = requests();
    try {
      setAdding(true);
      value.schema = schema;
      value.for = discountFor;
      if (schema !== "Buy X get Y") {
        value.conditions = [
          {
            condition_type: value.condition_type,
            condition_type_ids: value.condition_type_ids,
          }
        ];


        if(value.discount_method === "Discount Code")
          {
            if(value.discount_method === "Discount Code")
              {
                if(value.customer_condition_type_ids)
                {
                  value.conditions=[...value.conditions,   {
                    condition_type: "customer",
                    condition_type_ids: value.customer_condition_type_ids,
                  },]
                }
              }
          }
      } else {
        value.buy_x_get_y = [
          {
            type: value.quantity ? "customer_buys" : "customer_spends",
            conditions: [
              {
                condition_type: value.condition_type,
                condition_type_ids: [1, 2],
              },
            ],
          },
          {
            type: "customer_gets",
            quantity: value.customer_quantity,
            conditions: [
              {
                condition_type: value.customer_condition_type,
                condition_type_ids: [1],
              },
            ],
          },
        ];
        delete value.customer_quantity;
        delete value.customer_condition_type;
        if (value.quantity) {
          value.buy_x_get_y[0].quantity = value.quantity;
          delete value.quantity;
        } else {
          value.buy_x_get_y[0].quantity = value.amount;
          delete value.amount;
        }

        value.conditions = [
          {
            condition_type: "customer",
            condition_type_ids: [1],
          },
        ];
      }

      if (value.discount_method === "Discount Code") {
        value.discount_method = "manual";
      } else {
        value.discount_method = "automated";
      }
      if (discountUses.includes("one-use-per-customer")) {
        value.limit_per_customer = true;
      }
      value.start_time = dayjs(value.start_time);
      value.code = value.name;

      if (endDate) {
        value.end_time = dayjs(value.end_time);
      }
      delete value.condition_type_ids;
      delete value.condition_type;
      delete value.customer_condition_type_ids;
      const response = await api.put(
        endpoints.edit_discount.replace("id", id),
        value,
        true
      );
      if ((response.message = "success")) {
        message.success("Discount added successfully");
        navigate(routes.DISCOUNTS.path);
      } else {
        message.error("failed to add discount");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAdding(true);
    }
  };

  function generateUniqueCode() {
    let code = "";
    function generateTimestampCode() {
      const timestamp = Date.now().toString(36);
      const randomPart = Math.random()
        .toString(36)
        .substring(2, 8)
        .toUpperCase();

      const code = `${timestamp}${randomPart}`;

      return code.substring(0, 15);
    }
    code = generateTimestampCode().toUpperCase();
    setCode(code);
    form.setFieldsValue({ name: code.toUpperCase() });
  }
  const getDiscountData = async () => {
    const api = requests();
    try {
      setLoading(true);
      const response = await api.get(endpoints.get_discounts + `/${id}`);

      setDiscountTimeline(response?.data?.activities)

      const {
        schema,
        code,
        name,
        status,
        for: discount_for,
        discount_method,
        type,
        value,
        conditions,
        customer_type,
        tier_type,
        end_date,
        end_time,
        limit_per_customer,
        usage_limit,
        minimum_purchase_amount,
        minimum_quantity_item,
      } = response?.data;
      setCode(code);
      form.setFieldsValue({ name: name, status: status });
      form.setFieldsValue({
        discount_method: `${
          discount_method === "manual" ? "Discount Code" : "Automatic discount"
        }`,
      });
      if (schema !== "Buy X get Y") {
        const [DiscountValue, CustomerEligibility] = conditions;
        console.log("DiscountValue", DiscountValue);
        form.setFieldsValue({
          condition_type: DiscountValue?.condition_type,
          condition_type_ids: DiscountValue?.condition_type_ids,
          type: type,
          value: value,
        });
        fetchData(
          DiscountValue?.condition_type,
          DiscountValue?.condition_type_ids,
          handleConditionTypeIds
        );
        if (discount_method === "manual") {
          if (customer_type === "Business")
            form.setFieldsValue({
              customer_type: customer_type,
              tier_type: tier_type,
            });
          else {
            form.setFieldsValue({
              customer_type: customer_type,
              customer_conditon_type_ids: CustomerEligibility.conditon_type_ids,
            });
            fetchData(
              "customers",
              CustomerEligibility?.condition_type_ids,
              handleCustomerConditionTypeIds
            );
          }
        }
      }
      if (end_date && end_time) {
        setEndDate(true);
        form.setFieldsValue({
          end_date: dayjs(end_date),
          end_time: moment(end_time, "HH:mm:ss"),
        });
      }
      let discount_uses = [];
      if (usage_limit) {
        discount_uses.push("number-of-times-discount");
        form.setFieldsValue({ usage_limit: usage_limit });
      }
      if (limit_per_customer) {
        discount_uses.push("one-use-per-customer");
      }
      setDiscountUses(discount_uses);

      if (parseFloat(minimum_purchase_amount) || minimum_quantity_item) {
        if (parseInt(minimum_purchase_amount)) {
          setMinRequirement(2);

          form.setFieldsValue({
            minimum_purchase_amount: parseFloat(minimum_purchase_amount),
          });
          setMinAmount(parseInt(minimum_purchase_amount));
        } else if (minimum_quantity_item) {
          setMinRequirement(3);
          form.setFieldsValue({ minimum_quantity_item: minimum_quantity_item });
          setMinQty(parseInt(minimum_quantity_item));
        } else {
          setMinRequirement(1);
        }
      }
      setSchema(schema);
      setDiscountFor(discount_for);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleConditionTypeIds = (value) => {
    form.setFieldsValue({
      condition_type_ids: value?.map((items) => items.id),
    });
    setConditionTypeIds(value);
  };

  const handleCustomerConditionTypeIds = (value) => {
    form.setFieldsValue({
      customer_condition_type_ids: value?.map((items) => items.id),
    });
    setCustomerConditionTypeIds(value);
  };

  useEffect(() => {
    getDiscountData();
  }, []);

  const formatDate = (date) => {
    const createdDate = dayjs(date);
    const currentDate = dayjs();
    const diffHours = currentDate.diff(createdDate, "hour");

    if (diffHours < 24) {
      return createdDate.fromNow();
    } else {
      return createdDate.format("MMMM D, YYYY");
    }
  };


  return (
    <AuthChecker
      permissions={[roleAndPermission.DiscountManagement.create]}
      redirectTo={-1}
    >
      <Spin spinning={loading}>
        <PageHeader
          title={
            loading ? (
              <Skeleton.Input active={true} />
            ) : (
              <div className="d-flex align-items-center">
                <Button
                  type="text"
                  size="small"
                  style={{ width: 35, height: 35, marginRight: 5 }}
                  onClick={() => navigate(-1)}
                >
                  <i
                    className="ri-arrow-left-line"
                    style={{ fontSize: 20, marginRight: 0 }}
                  ></i>
                </Button>
                {`Edit ${discountFor?.toLowerCase()}`}
              </div>
            )
          }
        />
        <Form
          layout="vertical"
          form={form}
          requiredMark={false}
          onFinish={addDiscount}
          scrollToFirstError
        >
          <Row gutter={[32, 24]} style={{ margin: 0, marginTop: 24 }}>
            <Col span={16}>
              {/*   ###########################          Card1  ################################ */}

              <Card
                size="small"
                title={schema}
                type="inner"
                bodyStyle={{ paddingTop: 24, paddingBottom: 0 }}
              >
                <Row gutter={[24]} style={{ margin: 0 }}>
                  <Col span={24} style={{ display: "none" }}>
                    <Form.Item
                      name="discount_method"
                      label="Method"
                      initialValue={"Discount Code"}
                    >
                      <Segmented
                        options={["Discount Code", "Automatic discount"]}
                        onChange={(e) => {
                          if (e === "Discount Code") {
                            setMinRequirement(1);
                          } else {
                            setMinRequirement(2);
                          }
                          setCode("");
                          form.setFieldsValue({ name: "" });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.discount_method !==
                        currentValues.discount_method
                      }
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      {({ getFieldValue }) => {
                        const method = getFieldValue("discount_method");
                        return (
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "This field is required",
                              },
                            ]}
                            name="name"
             
                          >
                               <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  fontSize:"0.87rem",
                                  minWidth: "100%",
                                  paddingBottom:"5px"
                                }}
                              >
                                <span>
                                  {`${
                                    method === "Discount Code"
                                      ? "Discount Code"
                                      : "Title"
                                  }`}
                                  <span style={{ color: "red" }}> *</span>
                                </span>
                                {method === "Discount Code" && (
                                  <button
                                    style={{
                                      // marginLeft: "10px",
                                      backgroundColor: "white",
                                      border: "none",
                                      color: "blue",
                                      cursor: "pointer",
                                    }}
                                    type="button"
                                    onClick={generateUniqueCode}
                                  >
                                    Generate random code
                                  </button>
                                )}
                              </div>
                            <Input
                              value={code}
                              onChange={(e) => {
                                setCode(e.target.value);
                                form.setFieldsValue({ name: e.target.value });
                              }}
                            />
                            <span style={{ color: "gray" }}>
                              {method === "Discount Code"
                                ? "Customers must enter this code at checkout."
                                : "Customers will see this in their cart and at checkout."}
                            </span>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name="status"
                      initialValue={"ACTIVE"}
                      label={
                        <span>
                          Status
                          <span style={{ color: "red" }}> *</span>
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                    >
                      <Select
                        options={[
                          {
                            label: "Active",
                            value: "ACTIVE",
                          },
                          {
                            label: "Inactive",
                            value: "INACTIVE",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              {/*  */}

              {/*   ###########################          Card2 Discounted value ############################ */}
              {(schema === "Amount off products" ||
                schema === "Amount off order") && (
                <Card
                  size="small"
                  title={"Discount value"}
                  type="inner"
                  style={{ marginTop: 24 }}
                  bodyStyle={{ paddingTop: 24, paddingBottom: 0 }}
                >
                  <Row gutter={[24]} style={{ margin: 0 }}>
                    <Col span={18}>
                      <Form.Item
                        name="type"
                        initialValue={"percentage"}
                        rules={[
                          {
                            required: true,
                            message: "this field is required",
                          },
                        ]}
                      >
                        <Select
                          options={[
                            {
                              label: "Percentage",
                              value: "percentage",
                            },
                            {
                              label: "Fixed Amount",
                              value: "fixed_amount",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.type !== currentValues.type
                        }
                        noStyle
                      >
                        {({ getFieldValue }) => {
                          const type = getFieldValue("type");

                          return (
                            <Form.Item
                              name={"value"}
                              rules={[
                                {
                                  required: true,
                                  message: "this field is required",
                                },

                                {
                                  type: "number",
                                  min: 1,
                                  transform(value) {
                                    return value ? Number(value) : 0;
                                  },
                                  message:
                                    "Value should not be negative or Zero",
                                },
                              ]}
                            >
                              {type === "percentage" ? (
                                <InputNumber
                                  min={1}
                                  addonAfter={"%"}
                                  type="number"
                                />
                              ) : (
                                <InputNumber
                                  min={1}
                                  addonAfter={process.env.REACT_APP_CURRENCY}
                                  type="number"
                                />
                              )}
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>

                    {schema === "Amount off order" ? (
                      <></>
                    ) : (
                      <Col span={24}>
                        <Form.Item
                          name="condition_type"
                          label="Applies to"
                          initialValue={"collection"}
                        >
                          <Select
                            options={[
                              {
                                label: "Specific Catalogs",
                                value: "collection",
                              },
                              {
                                label: "Specific Products",
                                value: "product",
                              },
                              {
                                label: "Specific Categories",
                                value: "category",
                              },
                            ]}
                            onChange={(e) => {
                              setConditionTypeIds([]);
                              form.setFieldValue({ condition_type_ids: [] });
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.condition_type !==
                            currentValues.condition_type
                          }
                          noStyle
                        >
                          {({ getFieldValue }) => {
                            const condition_type =
                              getFieldValue("condition_type");
                            return (
                              <>
                                <Form.Item
                                  name={"condition_type_ids"}
                                  rules={[
                                    {
                                      required: true,
                                      message: "this field is required",
                                      // validator: (_, value) =>
                                      //     value && value.length > 0
                                      //         ? Promise.resolve()
                                      //         : Promise.reject(new Error('Please select at least one ID!')),
                                    },
                                  ]}
                                >
                                  <BrowseBox
                                    searchfor={`${
                                      condition_type === "collection"
                                        ? "catalog"
                                        : condition_type
                                    }`}
                                    conditionTypeIds={conditionTypeIds}
                                    setConditionTypeIds={handleConditionTypeIds}
                                  />
                                </Form.Item>
                              </>
                            );
                          }}
                        </Form.Item>

                        {/* <Input onClick={()=>showModal(true)} placeholder={`Search ${form.getFieldValue("condition_type")==="collection"?"catalog":form.getFieldValue("condition_type")}`}/> */}
                      </Col>
                    )}
                  </Row>
                </Card>
              )}

              {/*--------------------------------------------- minimum purchase requiment--------------------- */}
              {schema === "Buy X get Y" ? (
                <Card
                  size="small"
                  title={` ${
                    minRequirement === 3 ? "Customer buys" : "Customer Spends"
                  }`}
                  type="inner"
                  style={{ marginTop: 24 }}
                  bodyStyle={{ paddingTop: 24, paddingBottom: 24 }}
                >
                  <Row gutter={[24]} style={{ margin: 0 }}>
                    <Col span={24}>
                      <Radio.Group
                        onChange={(e) => {
                          setMinRequirement(e.target.value);
                        }}
                        value={minRequirement}
                      >
                        <Space direction="vertical">
                          <Radio value={3}>Minimum quantity of items</Radio>
                          <Radio value={2}>
                            Minimum purchase amount
                            {process.env.REACT_APP_CURRENCY}
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Col>
                    <Col span={6} style={{ marginTop: "10px" }}>
                      {minRequirement === 3 ? (
                        <Form.Item
                          name={"quantity"}
                          label={
                            <span>
                              Quantity <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: "this field is required",
                            },

                            {
                              type: "number",
                              min: 1,
                              transform(value) {
                                return value ? Number(value) : 0;
                              },
                              message: "Value should not be negative or Zero",
                            },
                          ]}
                        >
                          <InputNumber min={1} />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          name={"amount"}
                          label={
                            <span>
                              Amount <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: "this field is required",
                            },

                            {
                              type: "number",
                              min: 1,
                              transform(value) {
                                return value ? Number(value) : 0;
                              },
                              message: "Value should not be negative or Zero",
                            },
                          ]}
                        >
                          <InputNumber min={1} />
                        </Form.Item>
                      )}
                    </Col>
                    <Col span={18} style={{ marginTop: "10px" }}>
                      <Form.Item
                        name="condition_type"
                        label="Any items from"
                        initialValue={"collection"}
                      >
                        <Select
                          options={[
                            {
                              label: "Specific Catalogs",
                              value: "collection",
                            },
                            {
                              label: "Specific Products",
                              value: "product",
                            },
                            {
                              label: "Specific Categories",
                              value: "category",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ marginTop: "15px" }}>
                      <strong> Customer gets</strong>
                      <br />
                      <span>
                        {" "}
                        Customers must add the quantity of items specified below
                        to their cart.
                      </span>
                    </Col>
                    <Col span={6} style={{ marginTop: "10px" }}>
                      <Form.Item
                        name={"customer_quantity"}
                        label={
                          <span>
                            Quantity <span style={{ color: "red" }}> *</span>
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                          {
                            type: "number",
                            min: 1,
                            transform(value) {
                              return value ? Number(value) : 0;
                            },
                            message: "Number should not be negative!",
                          },
                        ]}
                      >
                        <InputNumber min={1} />
                      </Form.Item>
                    </Col>
                    <Col span={18} style={{ marginTop: "10px" }}>
                      <Form.Item
                        name="customer_condition_type"
                        label="Any items from"
                        initialValue={"collection"}
                      >
                        <Select
                          options={[
                            {
                              label: "Specific Catalogs",
                              value: "collection",
                            },
                            {
                              label: "Specific Products",
                              value: "product",
                            },
                            {
                              label: "Specific Categories",
                              value: "category",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24} style={{ marginTop: "15px" }}>
                      <strong> Discounted At</strong>
                      <br />
                    </Col>
                    <Col span={24}>
                      <>
                        <Form.Item
                          name="type"
                          rules={[
                            {
                              required: true,
                              message: "Please select a discount type!",
                            },
                          ]}
                          initialValue="percentage"
                        >
                          <Radio.Group>
                            <Space direction="vertical" size="small">
                              <Radio value="percentage">Percentage</Radio>
                              <div style={{ paddingLeft: "20px" }}>
                                <Form.Item shouldUpdate noStyle>
                                  {({ getFieldValue }) =>
                                    getFieldValue("type") === "percentage" && (
                                      <Form.Item
                                        name="value"
                                        rules={[
                                          {
                                            required: true,
                                            message: "This field is required",
                                          },
                                          {
                                            type: "number",
                                            min: 1,
                                            transform(value) {
                                              return value ? Number(value) : 0;
                                            },
                                            message:
                                              "Value should not be negative or zero",
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          min={1}
                                          addonAfter="%"
                                          style={{ width: "100%" }}
                                        />
                                      </Form.Item>
                                    )
                                  }
                                </Form.Item>
                              </div>

                              <Radio value="fixed_amount">
                                Amount off each
                              </Radio>

                              <div style={{ paddingLeft: "20px" }}>
                                <Form.Item shouldUpdate noStyle>
                                  {({ getFieldValue }) =>
                                    getFieldValue("type") ===
                                      "fixed_amount" && (
                                      <Form.Item
                                        name="value"
                                        rules={[
                                          {
                                            required: true,
                                            message: "This field is required",
                                          },
                                          {
                                            type: "number",
                                            min: 1,
                                            transform(value) {
                                              return value ? Number(value) : 0;
                                            },
                                            message:
                                              "Value should not be negative or zero",
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          min={1}
                                          addonAfter={
                                            process.env.REACT_APP_CURRENCY
                                          }
                                          style={{ width: "100%" }}
                                        />
                                      </Form.Item>
                                    )
                                  }
                                </Form.Item>
                              </div>

                              <Radio value="free">Free</Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </>
                    </Col>
                  </Row>
                </Card>
              ) : (
                <Card
                  size="small"
                  title={"Minimum purchase requirements"}
                  type="inner"
                  style={{ marginTop: 24 }}
                  bodyStyle={{ paddingTop: 24, paddingBottom: 24 }}
                >
                  <Row gutter={[24]} style={{ margin: 0 }}>
                    <Col span={16}>
                      <Radio.Group
                        onChange={(e) => {
                          setMinRequirement(e.target.value);
                        }}
                        value={minRequirement}
                      >
                        <Space direction="vertical">
                          <Form.Item
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues.discount_method !==
                              currentValues.discount_method
                            }
                            noStyle
                          >
                            {({ getFieldValue }) => {
                              const method = getFieldValue("discount_method");
                              if (method !== "Discount Code") {
                                return <></>;
                              } else {
                                return (
                                  <Radio value={1}>
                                    No minimum requirement
                                  </Radio>
                                );
                              }
                            }}
                          </Form.Item>

                          <Radio value={2}>
                            Minimum Purchase Amount
                            {process.env.REACT_APP_CURRENCY}
                          </Radio>
                          {minRequirement === 2 && (
                            <div
                              style={{
                                width: "100%",
                                paddingLeft: "20px",
                              }}
                            >
                              <Form.Item
                                name="minimum_purchase_amount"
                                rules={[
                                  {
                                    required: true,
                                    message: "this field is required",
                                  },

                                  {
                                    type: "number",
                                    min: 1,
                                    transform(value) {
                                      return value ? Number(value) : 0;
                                    },
                                    message:
                                      "Value should not be negative or Zero",
                                  },
                                ]}
                              >
                                <>
                                  <InputNumber
                                    onChange={(e) => {
                                      form.setFieldsValue({
                                        minimum_purchase_quantity: "",
                                      });
                                      form.setFieldsValue({
                                        minimum_purchase_amount: e,
                                      });
                                      setMinAmount(e);
                                      setMinQty(e);
                                    }}
                                    value={minAmount}
                                    min={1}
                                    addonAfter={process.env.REACT_APP_CURRENCY}
                                  />
                                  {schema === "Amount off order" ||
                                  schema === "Free shipping" ? (
                                    <>
                                      <br />
                                      <span style={{ color: "grey" }}>
                                        Applies to all products.
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <br />
                                      {form.getFieldValue("condition_type") ===
                                        "collection" && (
                                        <span style={{ color: "grey" }}>
                                          Applies only to selected catalogs.
                                        </span>
                                      )}
                                      {form.getFieldValue("condition_type") ===
                                        "product" && (
                                        <span style={{ color: "grey" }}>
                                          Applies only to selected products.
                                        </span>
                                      )}
                                      {form.getFieldValue("condition_type") ===
                                        "category" && (
                                        <span style={{ color: "grey" }}>
                                          Applies only to selected categories.
                                        </span>
                                      )}
                                    </>
                                  )}
                                </>
                              </Form.Item>
                            </div>
                          )}
                          <Radio value={3}>Minimum quantity of items</Radio>
                          <div
                            style={{
                              width: "100%",
                              paddingLeft: "20px",
                            }}
                          >
                            {minRequirement === 3 && (
                              <Form.Item
                                name="minimum_purchase_quantity"
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required",
                                  },
                                  {
                                    type: "number",
                                    min: 1,
                                    transform(value) {
                                      return value ? Number(value) : 0;
                                    },
                                    message:
                                      "Value should not be negative or zero",
                                  },
                                ]}
                              >
                                <>
                                  <InputNumber
                                    onChange={(e) => {
                                      form.setFieldsValue({
                                        minimum_purchase_amount: "",
                                      });
                                      form.setFieldsValue({
                                        minimum_purchase_quantity: e,
                                      });
                                      setMinAmount("");
                                      setMinQty(e);
                                    }}
                                    min={1}
                                    addonAfter={process.env.REACT_APP_CURRENCY}
                                    value={minQty}
                                  />
                                  {schema === "Amount off order" ||
                                  schema === "Free shipping" ? (
                                    <>
                                      <br />
                                      <span style={{ color: "grey" }}>
                                        Applies to all products.
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <br />
                                      {form.getFieldValue("condition_type") ===
                                        "collection" && (
                                        <span style={{ color: "grey" }}>
                                          Applies only to selected catalogs.
                                        </span>
                                      )}
                                      {form.getFieldValue("condition_type") ===
                                        "product" && (
                                        <span style={{ color: "grey" }}>
                                          Applies only to selected products.
                                        </span>
                                      )}
                                      {form.getFieldValue("condition_type") ===
                                        "category" && (
                                        <span style={{ color: "grey" }}>
                                          Applies only to selected categories.
                                        </span>
                                      )}
                                    </>
                                  )}
                                </>
                              </Form.Item>
                            )}
                          </div>
                        </Space>
                      </Radio.Group>
                    </Col>
                  </Row>
                </Card>
              )}

              {
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.discount_method !== currentValues.discount_method
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    const method = getFieldValue("discount_method");
                    return (
                      <>
                        {method === "Discount Code" && (
                          <>
                            {/* -----------------------------Customer Eligibility-------------------------------------------- */}
                            <Card
                              size="small"
                              title={"Cutomer Eligibility"}
                              type="inner"
                              style={{ marginTop: 24 }}
                              bodyStyle={{ paddingTop: 24, paddingBottom: 0 }}
                            >
                              <Row gutter={[24]} style={{ margin: 0 }}>
                                <Col span={24}>
                                  <Form.Item
                                    name={"customer_type"}
                                    initialValue={"Individual"}
                                  >
                                    <Radio.Group
                                      onChange={(e) => {
                                        if (e.target.value === "Business") {
                                          form.setFieldsValue({
                                            customer_condition_type_ids: [],
                                          });
                                          setCustomerConditionTypeIds([]);
                                        }
                                      }}
                                    >
                                      <Space direction="vertical">
                                        <Radio value={"Individual"}>
                                          Individual
                                        </Radio>

                                        <Radio value={"Business"}>
                                          Business
                                        </Radio>
                                      </Space>
                                    </Radio.Group>
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    shouldUpdate={(prevValues, currentValues) =>
                                      prevValues.customer_type !==
                                      currentValues.customer_type
                                    }
                                    noStyle
                                  >
                                    {({ getFieldValue }) => {
                                      const customer_type =
                                        getFieldValue("customer_type");
                                      return (
                                        <>
                                          {customer_type === "Individual" && (
                                            <Form.Item
                                              name={
                                                "customer_condition_type_ids"
                                              }
                                  
                                            >
                                              <BrowseBox
                                                searchfor={`customers`}
                                                conditionTypeIds={
                                                  customerConditionTypeIds
                                                }
                                                setConditionTypeIds={
                                                  handleCustomerConditionTypeIds
                                                }
                                              />
                                            </Form.Item>
                                          )}
                                          {customer_type === "Business" && (
                                            <Form.Item
                                              name={"tier_type"}
                                              initialValue={"tier4"}
                                              label={
                                                <span>
                                                  Select Tier Type{" "}
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    *
                                                  </span>
                                                </span>
                                              }
                                            >
                                              <Select
                                                options={[
                                                  {
                                                    label: "Tier 1",
                                                    value: "tier1",
                                                  },
                                                  {
                                                    label: "Tier 2",
                                                    value: "tier2",
                                                  },
                                                  {
                                                    label: "Tier 3",
                                                    value: "tier3",
                                                  },
                                                  {
                                                    label: "Tier 4",
                                                    value: "tier4",
                                                  },
                                                ]}
                                              />
                                            </Form.Item>
                                          )}
                                        </>
                                      );
                                    }}
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Card>

                            {/* --------------------------------------- Maximum discount uses----------------------------------- */}
                            <Card
                              size="small"
                              title={"Maximum discount uses"}
                              type="inner"
                              style={{ marginTop: 24 }}
                              bodyStyle={{ paddingTop: 24, paddingBottom: 24 }}
                            >
                              <Row gutter={[24]} style={{ margin: 0 }}>
                                <Col span={18}>
                                  <Checkbox.Group
                                    style={{
                                      width: "100%",
                                    }}
                                    value={discountUses}
                                    onChange={(value) => setDiscountUses(value)}
                                  >
                                    <Space direction="vertical">
                                      <Checkbox value="number-of-times-discount">
                                        Limit number of times this discount can
                                        be used in total
                                      </Checkbox>
                                      {discountUses.includes(
                                        "number-of-times-discount"
                                      ) ? (
                                        <div style={{ paddingLeft: "20px" }}>
                                          <Form.Item
                                            name={"usage_limit"}
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "this field is required",
                                              },
                                              {
                                                type: "number",
                                                min: 1,
                                                transform(value) {
                                                  return value
                                                    ? Number(value)
                                                    : 0;
                                                },
                                                message:
                                                  "Number should not be negative!",
                                              },
                                            ]}
                                          >
                                            <InputNumber
                                              min={1}
                                              onChange={(e) =>
                                                form.setFieldsValue({
                                                  usage_limit: e,
                                                })
                                              }
                                            />
                                          </Form.Item>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                      <Checkbox value="one-use-per-customer">
                                        Limit to one use per customer
                                      </Checkbox>
                                    </Space>
                                  </Checkbox.Group>
                                </Col>
                              </Row>
                            </Card>
                          </>
                        )}
                      </>
                    );
                  }}
                </Form.Item>
              }

              {/* ------Active Time */}
              <Card
                size="small"
                title={"Active Dates"}
                type="inner"
                style={{ marginTop: 24 }}
                bodyStyle={{ padding: 24 }}
              >
                <Row gutter={[24]}>
                  <Col span={18}>
                    <Form.Item
                      name={"start_date"}
                      initialValue={dayjs(getCurrentDate(), "YYYY/MM/DD")}
                      rules={[
                        { required: true, message: "this field is required" },
                      ]}
                    >
                      <DatePicker
                        disabledDate={disabledDate}
                        format={"YYYY/MM/DD"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={"start_time"}
                      initialValue={moment()}
                      rules={[
                        { required: true, message: "this field is required" },
                      ]}
                    >
                      <TimePicker />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      checked={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.checked);
                      }}
                    >
                      Set end date
                    </Checkbox>
                  </Col>

                  {endDate && (
                    <>
                      <Col span={18}>
                        <Form.Item
                          name={"end_date"}
                          initialValue={dayjs(getCurrentDate(), "YYYY/MM/DD")}
                          rules={[
                            {
                              required: true,
                              message: "this field is required",
                            },
                          ]}
                        >
                          <DatePicker
                            disabledDate={disabledDate}
                            format={"YYYY/MM/DD"}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name={"end_time"}
                          initialValue={moment()}
                          rules={[
                            {
                              required: true,
                              message: "this field is required",
                            },
                          ]}
                        >
                          <TimePicker />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </Card>
              <Row gutter={[24, 24]}>
                      <Col span={24} style={{ marginTop: 48}}>
                        <Timeline>
                          {discountTimeline &&
                            discountTimeline
                              .map((activity) => (
                                <Timeline.Item key={activity.id}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div>
                                      <small>
                                        {dayjs(activity.created_at).format(
                                          "hh:mm A"
                                        )}
                                      </small>
                                      <p>{activity.description} by <Link to={routes.EDIT_USER.path.replace(":id", activity?.causer?.id)} style={{ textDecoration: 'underline'}}><b>{activity?.causer?.customer_name}</b></Link></p>
                                    </div>
                                    <p>
                                      {formatDate(activity.created_at)}
                                    </p>
                                  </div>
                                </Timeline.Item>
                              ))}
                        </Timeline>
                      </Col>
                    </Row>
            </Col>

            <Col span={8}>
              {/* #########################Summary ############################## */}
              <Card
                size="small"
                title={"Summary"}
                type="inner"
                bodyStyle={{ padding: 24 }}
              >
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.discount_method !==
                      currentValues.discount_method ||
                    prevValues.usage_limit !== currentValues.usage_limit ||
                    prevValues.status !== currentValues.status
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    const method = getFieldValue("discount_method");
                    const usage_limit = getFieldValue("usage_limit");
                    const status = getFieldValue("status");
                    return (
                      <>
                        {method === "Discount Code" ? (
                          <div style={{ paddingBottom: "20px" }}>
                            {code ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <strong>{code}</strong>
                                  <Tooltip title="Copy Code" placement="right">
                                    <AiOutlineCopy
                                      size={16}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(code);
                                        message.success("Copied to clipboard");
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </Tooltip>
                                </div>
                                <span>{status}</span>
                              </div>
                            ) : (
                              <strong>No discount code yet</strong>
                            )}
                            <div style={{ marginTop: "10px" }}>
                              <strong>Type and method</strong>
                              <ul>
                                <li>{schema}</li>
                                <li>Code</li>
                              </ul>
                            </div>
                            <div>
                              <strong style={{ marginTop: "10px" }}>
                                Details
                              </strong>
                              {code ? (
                                <ul>
                                  <li>For Online Store</li>
                                  {minRequirement === 1 ? (
                                    <li>No minimum purchase requirement</li>
                                  ) : (
                                    <></>
                                  )}
                                  {/* <li>All customers</li> */}
                                  <li>
                                    {discountUses.length !== 0 ? (
                                      <>
                                        {discountUses.includes(
                                          "number-of-times-discount"
                                        ) &&
                                          usage_limit && (
                                            <>
                                              Limit of {usage_limit} uses
                                              {discountUses.length > 1
                                                ? ","
                                                : ""}
                                            </>
                                          )}
                                        {discountUses.includes(
                                          "one-use-per-customer"
                                        ) && "One per customer"}
                                      </>
                                    ) : (
                                      "No usage limits"
                                    )}
                                  </li>

                                  <li>Active from today</li>
                                </ul>
                              ) : (
                                <ul>
                                  <li>Can’t combine with other discounts</li>
                                </ul>
                              )}
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <strong>Performance</strong>
                              <br />
                              <span>Discount is not active yet</span>
                            </div>
                          </div>
                        ) : (
                          <div style={{ paddingBottom: "20px" }}>
                            {code ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <strong>{code}</strong>
                                  <Tooltip title="Copy Code" placement="right">
                                    <AiOutlineCopy
                                      size={16}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(code);
                                        message.success("Copied to clipboard");
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </Tooltip>
                                </div>
                                <span>{status}</span>
                              </div>
                            ) : (
                              <strong>No title yet</strong>
                            )}
                            <div style={{ marginTop: "10px" }}>
                              <strong>Type and method</strong>
                              <ul>
                                <li>{schema}</li>
                                <li>Automatic</li>
                              </ul>
                            </div>
                            <div>
                              <strong style={{ marginTop: "10px" }}>
                                Details
                              </strong>
                              {code ? (
                                <ul>
                                  <li>For Online Store</li>
                                  {minRequirement === 1 ? (
                                    <li>No minimum purchase requirement</li>
                                  ) : (
                                    <></>
                                  )}
                                  {/* <li>All customers</li> */}
                                  <li>
                                    {discountUses.length !== 0 ? (
                                      <>
                                        {discountUses.includes(
                                          "number-of-times-discount"
                                        ) &&
                                          usage_limit && (
                                            <>
                                              Limit of {usage_limit} uses
                                              {discountUses.length > 1
                                                ? ","
                                                : ""}
                                            </>
                                          )}
                                        {discountUses.includes(
                                          "one-use-per-customer"
                                        ) && "One per customer"}
                                      </>
                                    ) : (
                                      "No usage limits"
                                    )}
                                  </li>

                                  <li>Active from today</li>
                                </ul>
                              ) : (
                                <ul>
                                  <li>Can’t combine with other discounts</li>
                                </ul>
                              )}
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <strong>Performance</strong>
                              <br />
                              <span>Discount is not active yet</span>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  }}
                </Form.Item>{" "}
                review this this and make it simple
              </Card>
            </Col>
          </Row>
          <div className="sticky-footer">
            <Flex justify="end" style={{ padding: 24 }} gap={16}>
              <Button onClick={() => navigate(-1)}>Cancel</Button>

              <Form.Item noStyle>
                {loading ? (
                  <Skeleton.Button />
                ) : adding ? (
                  <Button type="primary" htmlType="submit" disabled={adding}>
                    <Spin indicator={<LoadingOutlined spin />} size="small" />{" "}
                    Updating ...
                  </Button>
                ) : (
                  <Button type="primary" htmlType="submit">
                    Update Discount
                  </Button>
                )}
              </Form.Item>
            </Flex>
          </div>
        </Form>
      </Spin>
    </AuthChecker>
  );
};

export default DiscountEdit;
