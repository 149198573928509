import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Col,
  Row,
  Input,
  Flex,
  Button,
  Select,
  Upload,
} from "antd";
import PageHeader from "../../../components/pageHeader";
import Dragger from "antd/es/upload/Dragger";
import UploadUtils from "../../../utilities/uploadUtils";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import requests from "../../../utilities/api";
import endpoints from "../../../constants/endpoints";
import FormItem from "antd/es/form/FormItem";

const HeaderForm = ({ mode, formFor, editLayout }) => {
  const [data, setData] = useState(formFor?.en?.data);
  const [loading, setloading] = useState(false);
  const [collection, setCollection] = useState([]);

  const getCollection = async () => {
    try {
      setloading(true);
      const api = requests();
      const response = await api.get(endpoints.get_collection);

      setCollection(
        response?.data?.data?.map((item) => ({
          label: item.name,
          value: item.slug,
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  const handleSubmit = (values) => {
    try {
      values.feature_image = UploadUtils.processUploadImage(
        values.feature_image
      );
      const seo_setting = [
        {
          id: formFor.seo_setting?.[0]?.id,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          feature_image: UploadUtils.processUploadImage(values.feature_image),
        },
      ];
      setData(data);
      editLayout(values, "Header", seo_setting);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCollection();
  }, [formFor]);

  return (
    <Form
      disabled={mode === "View"}
      layout="vertical"
      initialValues={data}
      onFinish={(values) => handleSubmit(values)}
    >
      <PageHeader title={`${formFor?.title}`} />
      <Row gutter={16} className="mt30">
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Customer Care">
            <Form.Item
              label="Customer Care Number"
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Header Items">
            <Form.List name="items">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 30,
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {fields.map((field, index) => (
                      <Card
                        size="small"
                        title={`Item ${index + 1}`}
                        key={field.key}
                        extra={
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      >
                        <Form.Item
                          label="Item Name"
                          name={[field.name, "item_name"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Item Type"
                          name={[field.name, "item_type"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Select
                            options={[
                              {
                                value: "link",
                                label: "Link",
                              },
                              {
                                value: "dropdown",
                                label: "Dropdown",
                              },
                            ]}
                          />
                        </Form.Item>

                        <Form.Item
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.items?.[index]?.item_type !==
                            currentValues.items?.[index]?.item_type
                          }
                          noStyle
                          //   dependencies={["item_type"]}
                        >
                          {({ getFieldValue }) => {
                            let type = getFieldValue([
                              "items",
                              index,
                              "item_type",
                            ]);

                            return (
                              <>
                                {type === "link" && (
                                  <>
                                    <Form.Item
                                      label={"Select Link Type"}
                                      name={[field.name, "link_type"]}
                                    >
                                      <Select
                                        options={[
                                          {
                                            value: "internal",
                                            label: "Internal",
                                          },
                                          {
                                            value: "external",
                                            label: "External",
                                          },
                                        ]}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      shouldUpdate={(
                                        prevValues,
                                        currentValues
                                      ) =>
                                        prevValues.items?.[index]?.link_type !==
                                        currentValues.items?.[index]?.link_type
                                      }
                                      noStyle
                                    >
                                      {({ getFieldValue }) => {
                                        let type = getFieldValue([
                                          "items",
                                          index,
                                          "link_type",
                                        ]);
                                        console.log(type);
                                        return (
                                          <>
                                            {type === "external" && (
                                              <Form.Item
                                                label={"Link Url"}
                                                name={[
                                                  field.name,
                                                  "redirect_path",
                                                ]}
                                              >
                                                <Input />
                                              </Form.Item>
                                            )}
                                            {type === "internal" && (
                                              <>
                                                <Form.Item
                                                  label={"Link Url"}
                                                  name={[
                                                    field.name,
                                                    "redirect_path",
                                                  ]}
                                                >
                                                  <Input addonBefore="https://repair-customer.zen5.io" />
                                                </Form.Item>
                                              </>
                                            )}
                                          </>
                                        );
                                      }}
                                    </Form.Item>
                                  </>
                                )}
                                {type === "dropdown" && (
                                  <Form.List
                                    name={[field.name, "options"]}
                                    initialValue={[1]}
                                  >
                                    {(innerfields, { add, remove }) => (
                                      <>
                                        <Row>
                                          <Col span={24}>
                                            {innerfields.map(
                                              (innerfield, indexNew) => (
                                                <Row
                                                  style={{ margin: 0 }}
                                                  gutter={12}
                                                >
                                                  <Col span={11}>
                                                    <Form.Item
                                                      label={
                                                        indexNew === 0
                                                          ? "Collection"
                                                          : ""
                                                      }
                                                      name={[
                                                        innerfield.name,
                                                        "collection",
                                                      ]}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                            "This field is required",
                                                        },
                                                      ]}
                                                    >
                                                      <Select
                                                        showSearch
                                                        optionFilterProp="label"
                                                        loading={loading}
                                                        filterSort={(
                                                          optionA,
                                                          optionB
                                                        ) =>
                                                          (optionA?.label ?? "")
                                                            .toLowerCase()
                                                            .localeCompare(
                                                              (
                                                                optionB?.label ??
                                                                ""
                                                              ).toLowerCase()
                                                            )
                                                        }
                                                        options={collection}
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                  <Col span={11}>
                                                    <Form.Item
                                                      label={
                                                        indexNew === 0
                                                          ? "Link Url"
                                                          : ""
                                                      }
                                                      name={[
                                                        innerfield.name,
                                                        "option_redirect_path",
                                                      ]}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                            "This field is required",
                                                        },
                                                      ]}
                                                    >
                                                      <Input addonBefore="https://repair-customer.zen5.io" />
                                                    </Form.Item>
                                                  </Col>
                                                  <Col span={2}>
                                                    <div
                                                      style={{
                                                        marginTop:
                                                          indexNew === 0
                                                            ? 36
                                                            : 4,
                                                      }}
                                                    >
                                                      <MinusCircleOutlined
                                                        onClick={() => {
                                                          remove(
                                                            innerfield.name
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                              )
                                            )}
                                          </Col>
                                        </Row>
                                        <Form.Item>
                                          <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            block
                                            icon={<PlusOutlined />}
                                          >
                                            Add Options
                                          </Button>
                                        </Form.Item>
                                      </>
                                    )}
                                  </Form.List>
                                )}
                              </>
                            );
                          }}
                        </Form.Item>
                      </Card>
                    ))}
                  </div>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Header Item
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Search Engine Listing">
            <Form.Item
              label="Meta Title"
              name="meta_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Meta Description"
              name="meta_description"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Feature Image"
              name="feature_image"
              {...UploadUtils.formItemProps}
              wrapperCol={{ span: 24 }}
            >
              <Upload {...UploadUtils.buttonPreviewProps}>
                <UploadUtils.CardContent />
              </Upload>
            </Form.Item>
          </Card>
        </Col>
        <Col span={24}>
          <Flex justify="end" style={{ padding: 24 }} gap={16}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Page
              </Button>
            </Form.Item>
          </Flex>
        </Col>
      </Row>
    </Form>
  );
};

export default HeaderForm;
