import Sidebar from "../components/sidebar";
import React from "react";
import {
  Layout,
  theme,
  Row,
  Col,
  Flex,
  Dropdown,
  Avatar,
  Drawer,
  Menu,
  Button,
} from "antd";
import themeConfig from "../config/theme.json";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../contexts/user";
import routes from "../constants/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { PiListBold } from "react-icons/pi";
import menus from "../constants/menu";
import FullLogo from "../images/infotainment-repair-logo-red.png";
// import FullLogo from "../images/logo-dark.svg";
import { Link } from "react-router-dom";
import { IoSettingsOutline } from "react-icons/io5";

const { Content } = Layout;

export default function withSidebar(ChildComponent) {
  const token = theme.getDesignToken(themeConfig);

  return function (props) {
    const userContext = useContext(UserContext);
    const [sidebarClosed, setSidebarClosed] = useState(false);
    const profileMenus = [
      {
        key: "1",
        label: <Link to={routes.PROFILE_UPDATE.path}>Update Profile</Link>,
      },
      {
        key: "2",
        label: (
          <span
            style={{
              cursor: "pointer",
              width: "100%",
              display: "inline-block",
            }}
            onClick={userContext.logout}
          >
            Logout
          </span>
        ),
      },
    ];
    const [isSmallScreen, setIsSmallScreen] = useState(
      window.innerWidth <= 1199
    );
    const [drawerOpen, setDrawerOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 1199);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return (
      <Layout>
        {isSmallScreen ? null : <Sidebar closed={sidebarClosed} />}
        <Layout>
          <Layout.Header
            style={{
              backgroundColor: token.colorWhite,
              zIndex: 99
            }}
          >
            {/* <Header /> */}
            <Row align='middle'>
              {isSmallScreen ? (
                <Col span={8}>
                  <Button
                    type='icon'
                    className='hamburger-responsive'
                    onClick={() => setDrawerOpen(true)}
                  >
                    <PiListBold />
                  </Button>
                  <Drawer
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    placement='left'
                    className='mobile-sidebar'
                    width={250}
                    bodyStyle={{
                      padding: 0,
                    }}
                  >
                    <Menu
                      theme='light'
                      className='sidebar'
                      mode='inline'
                      selectedKeys={[location.pathname || "/"]}
                      items={menus
                        .filter((item) => {
                          if (
                            item.requiredPermissions.filter(
                              (n) =>
                                userContext?.user?.permissions.indexOf(n) !== -1
                            ).length > 0
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        })
                        .map(({ requiredPermissions, ...rest }) => rest)}
                      onSelect={(item) => {
                        // debugger;
                        return item?.item?.props?.labelOnly === true
                          ? null
                          : navigate(item.key);
                      }}
                    />
                  </Drawer>
                </Col>
              ) : null}
              <Col span={isSmallScreen ? 8 : 0}>
                <Link to={routes.DASHBOARD.path}>
                  <Flex justify={isSmallScreen ? "center" : "start"}>
                    {/* {isSmallScreen ? ( */}

                    <img
                      src={FullLogo}
                      className='nav-logo-responsive'
                      alt='Logo'
                    />

                    {/* ) : null} */}
                  </Flex>
                </Link>
              </Col>
              <Col span={isSmallScreen ? 0 : 12}>
                <Button
                  type='icon'
                  className='sidebar-responsive'
                  onClick={() => setSidebarClosed(!sidebarClosed)}
                >
                  <PiListBold />
                </Button>
              </Col>
              <Col span={isSmallScreen ? 8 : 12}>
                <Flex align='center' justify='end' gap={16}>
                  <Link to={routes.HEADER_SETTING.path}>
                    <Button type="text" size="small">
                      <Flex justify="center" align="center"><IoSettingsOutline size={18} /></Flex>
                    </Button>
                  </Link>
                  <Dropdown
                    menu={{
                      items: profileMenus,
                    }}
                    placement='bottomRight'
                    trigger={["click"]}
                  >
                    <Flex
                      align='center'
                      gap={10}
                      className='header-user-card-container'
                    >
                      <Avatar
                        style={{
                          backgroundColor: "#cfe2fd",
                          color: "#1f3f7d",
                        }}
                        // size='small'
                        src={userContext?.user?.profile?.profile_pic?.[0]?.url}
                      >
                        {userContext?.user?.email.charAt(0)}
                      </Avatar>
                      {!isSmallScreen ? (
                        <div className='header-user-card'>
                          <div className='user-details'>
                            <div className='user-details-name'>
                              {userContext?.user && userContext?.user?.profile
                                ? `${userContext.user.profile.first_name ?? ""
                                } ${userContext.user.profile.last_name ?? ""}`
                                : ""}
                            </div>
                            <div className='user-details-email'>
                              {userContext?.user?.email}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </Flex>
                  </Dropdown>
                </Flex>
              </Col>
            </Row>
          </Layout.Header>
          <Content>
            <ChildComponent {...props} />
          </Content>
        </Layout>
      </Layout>
    );
  };
}
