import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Card,
  Col,
  Row,
  Input,
  Flex,
  Button,
  DatePicker,
  InputNumber,
  Upload,
} from "antd";
import PageHeader from "../../../components/pageHeader";
import Dragger from "antd/es/upload/Dragger";
import UploadUtils from "../../../utilities/uploadUtils";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import dayjs from "dayjs";

const { TextArea } = Input;

const TermConditionsForm = ({ mode, formFor, editLayout }) => {
  const [data, setData] = useState(formFor?.en?.data);

  const handleSubmit = (values) => {
    try {
      values.feature_image = UploadUtils.processUploadImage(
        values.feature_image
      );
      const seo_setting = [
        {
          id: formFor.seo_setting?.[0]?.id,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          feature_image: values.feature_image,
        },
      ];

      setData(values);
      editLayout(values, "Term & Conditions", seo_setting);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form
      disabled={mode === "View"}
      layout="vertical"
      initialValues={data}
      onFinish={(values) => handleSubmit(values)}
    >
      <PageHeader title={`${formFor?.title?.replace("-", " ")} Page`} />
      <Row gutter={16} className="mt30">
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Terms & Conditions Description">
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <ReactQuill theme="snow" rows={4} />
            </Form.Item>
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Term & Condition Section">
            <Form.List name="term&condtions">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 30,
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {" "}
                    {fields.map((field, index) => (
                      <Card
                        size="small"
                        title={`Term & Condition ${index + 1}`}
                        key={field.key}
                        extra={
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      >
                        <Form.Item
                          label="Title"
                          name={[field.name, "name"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Terms & Conditions Description"
                          name={[field.name, "description"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <ReactQuill theme="snow" rows={4} />
                        </Form.Item>
                      </Card>
                    ))}
                  </div>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Term & Conditions
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>
          </Card>
        </Col>

        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Search Engine Listing">
            <Form.Item
              label="Meta Title"
              name="meta_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Meta Description"
              name="meta_description"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <ReactQuill theme="snow" rows={4} />
            </Form.Item>
            <Form.Item
              label="Feature Image"
              name="feature_image"
              {...UploadUtils.formItemProps}
              wrapperCol={{ span: 24 }}
            >
              <Upload {...UploadUtils.buttonPreviewProps}>
                <UploadUtils.CardContent />
              </Upload>
            </Form.Item>
          </Card>
        </Col>
        <Col span={24}>
          <Flex justify="end" style={{ padding: 24 }} gap={16}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Page
              </Button>
            </Form.Item>
          </Flex>
        </Col>
      </Row>
    </Form>
  );
};

export default TermConditionsForm;
