import React from "react";
import { Helmet } from "react-helmet";

export default function withHelmet(title, ChildComponent) {
  return class extends React.Component {
    render() {
      return (
        <>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <ChildComponent {...this.props} />
        </>
      );
    }
  };
}
